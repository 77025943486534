@charset "utf-8";

#header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 13.4rem;
    background: #fff;
    z-index: 100;
}

#header .header_wrap {
    transition: background .3s ease;
}
#header .lang_area .dropdown {
    position: relative;
    width: 102px;
}

#header.open .header_wrap {
    background: #fff;
    height: 28rem;
}

#header.open .nav_gnb .nav_list .menu_dep2 {
    opacity: 1;
    visibility: visible;
}

#header .h_logo {
    position: absolute;
    left: 0;
    top: 3.5rem;
}

#header .h_logo a {
    display: block;
    overflow: hidden;
    width: 14.2rem;
    height: 6.4rem;
    font-size: 0;
    text-indent: -9999px;
    background: url(../images/h_logo.png) no-repeat center top;
}

#header .nav_gnb .nav_list_items {
    padding-top: 4.2rem;
    padding-left: 32rem;
}

#header .nav_gnb .nav_list {
    position: relative;
    /* text-align: center; */
}

#header .nav_gnb .nav_list+.nav_list {
    margin-left: 3rem;
}

#header .nav_gnb .nav_list .menu_dep1 {
    padding: 1rem;
}

#header .nav_gnb .nav_list .menu_dep2 {
    position: absolute;
    width: 10rem;
    padding-bottom: 4.6rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
}

#header .nav_gnb .nav_list .menu_dep2 a {
    padding: .4rem 1rem;
    line-height: 1.57;
    font-size: 1.4rem;
    white-space: nowrap;
    text-align: left;
}

#header .lang_area {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50px;
}

#header .lang_area .btn_dropdown {
    display: block;
    min-width: 11.1rem;
    height: 3.4rem;
    padding: 0 2.9rem 0 1rem;
    text-align: right;
    font-size: 1.6rem;
    color: #111;
    letter-spacing: -.1rem;
    background: url(../images/icon_arrow_down.png) no-repeat right 1rem center;
}

#header .lang_area .btn_dropdown.jpn {
    font-family: 'Noto Sans JP', sans-serif;
}

#header .lang_area .btn_dropdown.ch_01,
#header .lang_area .btn_dropdown.ch_02 {
    font-family: 'Noto Sans SC', sans-serif;
}

#header .lang_area .dropdown_items {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-62%);
    margin-bottom: -.8rem;
    padding: 1.6rem 2rem;
    border: 1px solid #d5d3e4;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
}

#header .lang_area .dropdown_items.box_sm {
    padding: 1.2rem;
}

#header .lang_area .dropdown.open .dropdown_items {
    opacity: 1;
    visibility: visible;
}

/* #header .lang_area .dropdown_items li:not(:last-child) {margin-bottom: .6rem;} */
#header .lang_area .dropdown_items li.selected {
    display: none;
}

#header .lang_area .dropdown_items li.soon {
    display: none;
}

#header .lang_area .dropdown_items a {
    display: block;
    font-size: 1.6rem;
    white-space: nowrap;
    margin-bottom: 0.6rem;
}

#header .lang_area .dropdown_items a .text {
    display: block;
    line-height: 1.625;
}

#header .lang_area .dropdown_items a .text.jpn {
    font-family: 'Noto Sans JP', sans-serif;
}

#header .lang_area .dropdown_items a .text.ch_01,
#header .lang_area .dropdown_items a .text.ch_02 {
    font-family: 'Noto Sans SC', sans-serif;
}

#header .lang_area .dropdown_items a small {
    display: block;
    font-size: 1.1rem;
    line-height: 1.5;
    letter-spacing: -.05rem;
    color: #828282;
}

#header .lang_area .dropdown_items a:hover small {
    color: #828282;
}

#header .lang_area .dropdown_items a.none_event {
    font-size: 1.3rem;
    line-height: 1.23;
    pointer-events: none;
}

#header .lang_area .dropdown_items a:hover {
    color: #743fa3;
}

#header .lang_area .dropdown_items a.none_eventa:hover {
    color: #111;
}

#header .lang_area .dropdown_items button {
    display: block;
    font-size: 1.6rem;
    white-space: nowrap;
    margin-bottom: 0.6rem;
    padding: 0;
    text-align: left;
}

#header .lang_area .dropdown_items button .text {
    display: block;
    line-height: 1.625;
}

#header .lang_area .dropdown_items button .text.jpn {
    font-family: 'Noto Sans JP', sans-serif;
}

#header .lang_area .dropdown_items button .text.ch_01,
#header .lang_area .dropdown_items button .text.ch_02 {
    font-family: 'Noto Sans SC', sans-serif;
}

#header .lang_area .dropdown_items button small {
    display: block;
    font-size: 1.1rem;
    line-height: 1.5;
    letter-spacing: -.05rem;
    color: #828282;
    text-align: left;
}

#header .lang_area .dropdown_items button:hover small {
    color: #828282;
}

#header .lang_area .dropdown_items button.none_event {
    font-size: 1.3rem;
    line-height: 1.23;
    pointer-events: none;
}

#header .lang_area .dropdown_items button:hover {
    color: #743fa3;
}

#header .lang_area .dropdown_items button.none_eventa:hover {
    color: #111;
}
#header .lang_area .dropdown_items span.none_event {
    display: block;
    white-space: nowrap;
    font-size: 1.3rem;
    line-height: 1.23;
    color: #000;
}

#header .btn_open_menu {
    display: none;
}

#header .dim {
    display: none;
}

.nav_gnb .nav_list_items {
    font-size: 0;
}

.nav_gnb .nav_list {
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.nav_gnb .nav_list .menu_dep1 {
    font-size: 1.8rem;
    line-height: 1.667;
    letter-spacing: -.05rem;
}

.nav_gnb .nav_list .menu_dep1,
.nav_gnb .nav_list .menu_dep2 a {
    display: block;
    transition: color .3s ease;
}

.nav_gnb .nav_list .menu_dep1.on,
.nav_gnb .nav_list .menu_dep1:hover,
.nav_gnb .nav_list .menu_dep2 .on a,
.nav_gnb .nav_list .menu_dep2 a:hover {
    color: #743fa3;
    /* height: 3.8rem; */
}
@media (hover: hover) {

    .nav_gnb .nav_list .menu_dep1.on,
    .nav_gnb .nav_list .menu_dep1:hover,
    .nav_gnb .nav_list .menu_dep2 .on,
    .nav_gnb .nav_list .menu_dep2 a:hover {
        color: #743fa3;
    }
}

.main #header {
    background: transparent;
}

.main #header.open .header_wrap {
    background: rgba(0, 0, 0, .7);
}

.main #container {
    padding-top: 0;
}

.main #header .h_logo a {
    background-image: url(../images/h_logo_white.png);
}

.main #header .nav_gnb .nav_list .menu_dep1,
.main #header .nav_gnb .nav_list .menu_dep2 a {
    color: #fff;
    text-align: left;
}

.main #header .nav_gnb .nav_list .menu_dep1.on,
.main #header .nav_gnb .nav_list .menu_dep1:hover,
.main #header .nav_gnb .nav_list .menu_dep2 .on a,
.main #header .nav_gnb .nav_list .menu_dep2 a:hover {
    color: #cb82ff;
}

.main #header .lang_area .btn_dropdown {
    color: #fff;
    background-image: url(../images/icon_arrow_down_white.png);
}

.main #header .lang_area .dropdown_items {
    border-color: transparent;
    background: rgba(6, 3, 8, 0.7);
}

.main #header .lang_area .dropdown_items a {
    color: #fff;
}

.main #header .lang_area .dropdown_items a:hover {
    color: #cb82ff;
}

.main #header .lang_area .dropdown_items a:hover small {
    color: #828282;
}

.main #header .lang_area .dropdown_items button {
    color: #fff;
}

.main #header .lang_area .dropdown_items button:hover {
    color: #cb82ff;
}

.main #header .lang_area .dropdown_items button:hover small {
    color: #828282;
}

.main #header .lang_area .dropdown_items span.none_event {
    color: #fff;
}
/************************ 반응형 1320px(S) ************************/
@media screen and (max-width: 1320px) {

    #header .h_logo {
        left: 2.4rem;
    }

    #header .nav_gnb .nav_list+.nav_list{
        margin-left:1rem;
    }

    #header .nav_gnb .nav_list_items {
        padding-left: 20%;
    }

    #header .lang_area {
        right: 2.4rem;
    }

}
/************************ 반응형 1320px(E) ************************/

/************************ 반응형 992px(S) ************************/
@media screen and (max-width: 992px) {  

    #header {
        height: 6.4rem;
        padding-top: env(safe-area-inset-top);
    }

    .fixed #header,
    #header.sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        animation: stickyani .2s linear 1 forwards;
    }

    #header .h_logo {
        position: absolute;
        left: 50%;
        top: 1.4rem;
        margin-left: -4.4rem;
    }

    #header .h_logo a {
        width: 8.8rem;
        height: 4rem;
        background-image: url(../images/h_logo_purple.svg);
        background-size: 100%;
    }

    #header .header_nav_wrap {
        position: fixed;
        left: auto;
        right: 0;
        top: 0;
        bottom: 0;
        width: 30rem;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
        z-index: -1;
        pointer-events: none;
    }

    #header .header_nav_wrap.open {
        opacity: 1;
        visibility: visible;
        z-index: 100;
        pointer-events: all;
    }

    #header .btn_open_menu {
        display: block;
        position: absolute;
        right: 1.9rem;
        top: 1.9rem;
        width: 3rem;
        height: 2.6rem;
        padding: 0;
        font-size: 0;
        text-indent: -9999px;
        outline: none;
        z-index: 101;
    }

    #header .btn_open_menu.open {
        position: fixed;
    }

    #header .btn_open_menu:before,
    #header .btn_open_menu:after,
    #header .btn_open_menu span {
        display: inline-block;
        position: absolute;
        left: 50%;
        width: 2rem;
        height: 2px;
        transform: translateX(-50%);
        background: #111;
    }

    #header .btn_open_menu:before {
        content: "";
        top: .5rem;
        transition: transform .3s cubic-bezier(.165, .84, .44, 1);
    }

    #header .btn_open_menu:after {
        content: "";
        bottom: .5rem;
        transition: transform .3s cubic-bezier(.165, .84, .44, 1);
    }

    #header .btn_open_menu span {
        top: 50%;
        margin-top: -1px;
        transition: opacity .3s cubic-bezier(.165, .84, .44, 1);
    }

    #header .btn_open_menu.open:before {
        transform: rotate(-45deg) translateX(-50%);
    }

    #header .btn_open_menu.open:after {
        transform: rotate(45deg) translateX(-50%);
    }

    #header .btn_open_menu.open span {
        opacity: 0;
    }

    #header .dim {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, .4);
        opacity: 0;
        visibility: hidden;
        z-index: -1;
    }

    #header .header_nav_wrap.open~.dim {
        opacity: 1;
        visibility: visible;
    }

    #header .header_menu_wrap {
        overflow-y: auto;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding-top: 6.4rem;
        background: #fff;
    }

    #header .nav_gnb .nav_list_items {
        padding: 2.4rem 3.2rem;
    }

    #header .nav_gnb .nav_list {
        display: block;
    }

    #header .nav_gnb .nav_list+.nav_list {
        margin: 1.6rem 0 0 0;
    }

    #header .nav_gnb .nav_list .menu_dep1 {
        padding: 0;
        font-size: 2.4rem;
        line-height: 1.4;
        font-weight: 700;
    }

    #header .nav_gnb .nav_list .menu_dep1:hover {
        color: #111;
    }
    @media (hover: hover) {
        #header .nav_gnb .nav_list .menu_dep1:hover {
            color: #743fa3;
        }
    }

    #header .nav_gnb .nav_list .menu_dep2 {
        position: relative;
        width: 100%;
        padding-top: 1.6rem;
        padding-bottom: .8rem;
        opacity: 1;
        visibility: visible;
    }

    #header .nav_gnb .nav_list .menu_dep2 li+li {
        margin-top: .8rem;
    }

    #header .nav_gnb .nav_list .menu_dep2 a {
        padding: 0;
        line-height: 1.6;
        font-size: 1.6rem;
        color: #828282;
    }
    #header .nav_gnb .nav_list .menu_dep2 .on {
        color: #743fa3;
    }

    #header .lang_area {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        user-select: none;
    }

    #header .lang_area .btn__ {
        display: block;
        height: 6.4rem;
        border-bottom: 1px solid #eee;
        background: #fff;
    }

    #header .lang_area .btn_dropdown {
        min-width: 0;
        height: 6.4rem;
        padding: 0 2.7rem;
        background: none;
    }

    #header .lang_area .btn_dropdown span {
        display: inline-block;
        position: relative;
        padding-left: 2.6rem;
    }

    #header .lang_area .btn_dropdown span:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 1.8rem;
        height: 1.8rem;
        margin-top: -.9rem;
        background: url('../images/icon_global.png') no-repeat left center;
        background-size: 100%;
    }

    #header .lang_area .dropdown_items {
        overflow-y: auto;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        transform: translateX(0);
        width: auto;
        height: calc(100vh - 6.4rem);
        margin-bottom: 0;
        padding: 2.7rem 3.4rem;
        border: 0;
    }

    #header .lang_area .dropdown {
        width: auto;
    }

    #header .lang_area .dropdown_items.box_sm {
        padding: 2.7rem 3.4rem;
    }

    #header .lang_area .dropdown_items li:not(:last-child) {
        margin-bottom: 3rem;
    }

    #header .lang_area .dropdown_items a {
        font-weight: 500;
    }

    #header .lang_area .dropdown_items a .text {
        display: inline-block;
        position: relative;
        padding-left: 3rem;
        letter-spacing: -.1rem;
    }

    #header .lang_area .dropdown_items a .text:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 2rem;
        height: 2rem;
        margin-top: -1rem;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    #header .lang_area .dropdown_items a .text.ko:before {
        background-image: url('../images/icon_ko.png');
    }

    #header .lang_area .dropdown_items a .text.en:before {
        background-image: url('../images/icon_en.png');
    }

    #header .lang_area .dropdown_items a .text.jpn:before {
        background-image: url('../images/icon_jpn.png');
    }

    #header .lang_area .dropdown_items a .text.ch_01:before {
        background-image: url('../images/icon_cn.png');
    }

    #header .lang_area .dropdown_items a .text.ch_02:before {
        background-image: url('../images/icon_cn.png');
    }

    #header .lang_area .dropdown_items a .text.gm:before {
        background-image: url('../images/icon_german.png');
    }

    #header .lang_area .dropdown_items a .text.fr:before {
        background-image: url('../images/icon_franch.png');
    }

    #header .lang_area .dropdown_items a .text.sp:before {
        background-image: url('../images/icon_spanish.png');
    }

    #header .lang_area .dropdown_items a small {
        display: inline-block;
        margin-left: .8rem;
        font-size: 1.2rem;
        font-weight: 400;
    }

    #header .lang_area .dropdown_items a:hover {
        color: #111;
    }

    #header .lang_area .dropdown_items li.soon {
        display: block;
    }

    #header .lang_area .dropdown_items li.soon a {
        opacity: .4;
        pointer-events: none;
        cursor: none;
    }

    #header .lang_area .dropdown_items button .text {
        color: #111;
    }

    #header .lang_area .dropdown_items button:hover .text {
        color: #6d429e;
    }

    .main #header {
        background: transparent;
    }

    .main #header.open .header_wrap {
        background: #fff;
    }

    .main #container {
        padding-top: 0;
    }

    .main #header .h_logo a {
        background-image: url(../images/h_logo_white.svg);
    }

    .main #header .nav_gnb .nav_list .menu_dep1 {
        color: #111;
    }

    .main #header .nav_gnb .nav_list .menu_dep2 a {
        color: #828282;
    }

    @media (hover: hover) {

        .main #header .nav_gnb .nav_list .menu_dep1.on,
        .main #header .nav_gnb .nav_list .menu_dep1:hover,
        .main #header .nav_gnb .nav_list .menu_dep2 a:hover {
            color: #743FA3;
        }
    }

    .main #header .lang_area .btn_dropdown {
        color: #111;
        background-image: none;
    }

    .main #header .lang_area .dropdown_items {
        background: #fff;
    }

    .main #header .lang_area .dropdown_items a {
        color: #111;
    }

    .main #header .lang_area .dropdown_items a:hover {
        color: #111;
    }
    .main #header .lang_area .dropdown_items span.none_event,
    #header .lang_area .dropdown_items span.none_event {
        font-weight: 500;
        color: #000;
    }

    .main #header .btn_open_menu:before,
    .main #header .btn_open_menu:after,
    .main #header .btn_open_menu span {
        background: #fff;
    }

    .main #header .btn_open_menu.open:before,
    .main #header .btn_open_menu.open:after,
    .main #header .btn_open_menu.open span {
        background: #111;
    }

    #header.sticky {
        background: #fff;
        border-bottom: 1px solid #eee;
    }

    #header.sticky .h_logo a {
        background-image: url(../images/h_logo_purple.svg);
    }

    #header.sticky .btn_open_menu:before,
    #header.sticky .btn_open_menu:after,
    #header.sticky .btn_open_menu span {
        background: #111;
    }

    

    

}
/************************ 반응형 992px(E) ************************/