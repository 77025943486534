@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Sans+KR:wght@400;500;700&family=Noto+Sans+SC:wght@400;500;700&family=Noto+Sans+TC:wght@400;500;700&family=Noto+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;500;700&display=swap');

:lang(ko) {
    font-family: 'Noto Sans KR';
}

:lang(en) {
    font-family: 'Noto Sans KR';
    letter-spacing: 0;
}

:lang(ja) {
    font-family: sans-serif;
    /* line-break: anywhere; */
    /* word-break: keep-all; */
    word-wrap: break-word;
}

:lang(zh-CN) {
    font-family: 'Microsoft Yahei', Avenir, Segoe UI, Hiragino Sans GB, STHeiti, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
}

:lang(zh-TW) {
    font-family: 'Noto Sans TC';
}


/************************
common
************************/
#root {
    height: 100%;
}

body,
html {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

* {
    word-break: keep-all;
}

a {
    cursor: pointer;
}

/* display */
.block {
    display: block !important;
}

.in_block {
    display: inline-block !important;
}

/* visible & hide */
.visible_lg {
    display: block !important;
}

.visible_md {
    display: none !important;
}

.visible_sm {
    display: none !important;
}

.visible_xs {
    display: none !important;
}

.hidden_lg {
    display: none !important;
}

.hidden_md {
    display: block !important;
}

.hidden_sm {
    display: block !important;
}

.hidden_xs {
    display: block !important;
}

/* float */
.clear:after,
.clear::after {
    content: "";
    display: table;
    clear: both;
}

.f_left {
    float: left !important;
}

.f_right {
    float: right !important;
}

.f_none {
    float: none !important;
}

/* table box */
.d_table {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.d_table>.d_cell {
    display: table-cell;
    vertical-align: middle;
}

/* flex box */
.d_flex {
    display: flex !important;
}

.d_flex.ai_center {
    align-items: center;
}

.d_flex.ai_start {
    align-items: flex-start;
}

.d_flex.ai_end {
    align-items: flex-end;
}

.d_flex.jc_center {
    justify-content: center;
}

.d_flex.jc_start {
    justify-content: flex-start;
}

.d_flex.jc_end {
    justify-content: flex-end;
}

/* column */
.col1 {
    width: 100%;
}

.col2 {
    width: 50%;
}

.col3 {
    width: 33.33333333333333%;
}

.col4 {
    width: 25%;
}

.col5 {
    width: 20%;
}

.col6 {
    width: 16.6666%;
}

/* text align */
.align_left {
    text-align: left !important;
}

.align_right {
    text-align: right !important;
}

.align_center {
    text-align: center !important;
}

/* text */
.text_primary {
    color: #743fa3 !important;
}

.text_normal {
    color: #444 !important;
}

.text_white {
    color: #fff !important;
}

.text_blk {
    color: #111 !important;
}

.underline {
    text-decoration: underline !important;
}

/* font */
.fw_400 {
    font-weight: 400 !important;
}

.fw_500 {
    font-weight: 500 !important;
}

.fw_700 {
    font-weight: 700 !important;
}

/* text style */
.h_title_box {
    margin-bottom: 4rem;
}
.h_title_box.mgb20{
    margin-bottom: 2rem;
}

.h_title {
    display: block;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.389;
    color: #743fa3;
}

.h_title_box .desc {
    margin-top: 2rem;
    font-size: 2.4rem;
    line-height: 1.5;
}

.h_title_box.inner,
.h_title_box.inner:last-child {
    margin: 0 auto 9rem;
}

.h_title_box:last-child {
    margin-bottom: 0;
}

.h_title_box:last-child .desc {
    padding-bottom: 0;
}

.cont+.h_title_box {
    margin-top: 4rem;
}

.s_title_box {
    margin: 6rem 0 4rem;
}

.s_title_box .s_title {
    display: inline-block;
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.25;
    color: #743fa3;
}

.s_title_box .btn_basic {
    display: inline-block;
    margin-left: 2.4rem;
    vertical-align: bottom;
}

/* button */
.btn_basic {
    display: inline-block;
    height: 3.6rem;
    padding: 0 2.75rem;
    line-height: 3.4rem;
    font-size: 1.4rem;
    letter-spacing: -.1rem;
    color: #743fa3;
    text-align: center;
    border: 1px solid rgba(116, 63, 163, 0.5);
    background: #fff;
    box-sizing: border-box;
    transition: all .3s ease-in-out;
}

button.btn_basic {
    line-height: 1;
}

button.btn_basic.off {
    line-height: 1;
    color: #5F6268;
    border: 1px solid #5F626880;
}
button[disabled]:hover {
    color: #FFF;
}

.btn_lg {
    height: 5.6rem;
    padding: 0 7.8rem;
    line-height: 5.4rem;
    font-size: 1.8rem;
}

.btn_xl {
    min-width: 24rem;
    height: 6rem;
    padding: 0 5.75rem;
    line-height: 5.8rem;
    font-size: 2.2rem;
}

.btn_home {
    min-width: 18.6rem;
    height: 5.6rem;
    padding: 0 1rem;
    line-height: 5.0rem;
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 2rem;
}

.btn_primary {
    background: #743fa3;
    color: #fff;
}

.btn_primary_inversion {
    background: #fff;
    color: #743fa3;
    border: 1px solid #743fa3;
}

.btn_primary_linear {
    position: relative;
    min-width: 22rem;
    height: 5.6rem;
    padding: 0 2rem;
    line-height: 5.0rem;
    font-size: 1.8rem;
    font-weight: 500;
    text-align: left;
    margin-right: 2rem;
    background: #743fa3;
    color: #1dd1d3;
    border: 1.4px solid #1dd1d3;
}

.btn_primary_linear img.arrow {
    position: absolute;
    top: 20px;
    right: 20px;
}


@media (hover: hover) {
    .btn_basic:hover {
        border: 1px solid #743fa300;
        background: #743fa3;
        color: #fff;
    }

    .btn_primary:hover {
        background: #1dd1d3;
        color: #743fa3;
    }

    .btn_primary_inversion:hover {
        background: #1dd1d3;
        color: #743fa3;
    }

    .btn_primary_linear:hover {
        background: #fff;
        color: #1DD1D3;
        border: #fff;
    }
}


.btn_primary.disabled,
.btn_primary:disabled {
    background: rgba(213, 211, 228, 0.8);
}

.btn_loading {
    position: relative;
    font-size: 0 !important;
}

.btn_loading:after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1.8rem;
    height: 1.8rem;
    margin: -.9rem 0 0 -.9rem;
    transform: rotate(0);
    background: url(../images/loading.png) no-repeat center center;
    background-size: 100%;
    animation: loading 1.5s linear infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* form */
.form_group {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -.4rem;
}

.form_group .form_input_box {
    position: relative;
    width: 100%;
    padding: 0 .4rem;
}

.form_group .form_input_box+.form_input_box {
    margin-top: 1.6rem;
}

.form_group .form_input_box.col2 {
    width: 50%;
}

.form_group .form_input {
    display: block;
    width: 100%;
    height: 5.6rem;
    padding: 0 1.6rem;
    color: #111;
    font-family: 'Noto Sans';
    font-size: 1.6rem;
    border: 1px solid #d5d3e4;
    background: #fafafb;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    box-sizing: border-box;
}

.form_group .form_input_box.row5 .form_input {
    padding: 0 1.6rem 5rem;
    height: 112px;
}

.form_group .form_input_box.error .form_input {
    border-color: #b3261e;
}

.form_group .form_input_box .form_input:focus {
    border-color: #743fa3;
}

.form_group .form_label {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0 1.6rem;
    line-height: 5.6rem;
    font-size: 1.6rem;
    color: #828282;
    font-weight: 400;
}

.form_group .form_label .requiry {
    color: #e6311a;
}

.form_group .form_input_box .error_text {
    margin: .8rem 0;
    color: #b3261e;
    font-size: 1.4rem;
    line-height: 1.6;
}
.error_text {
    margin: .8rem 0;
    color: #b3261e;
    font-size: 1.4rem;
    line-height: 1.6;
}

.form_wrap .check_wrap {
    position: relative;
    margin-top: 1.6rem;
}

.form_wrap .check_wrap input[type=checkbox] {
    position: absolute;
    opacity: 0;
}

.form_wrap .check_wrap label {
    display: block;
    position: relative;
    padding-left: 3rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #444;
}

.form_wrap .check_wrap label:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 14px;
    width: 2rem;
    height: 2rem;
    margin-top: -1rem;
    border: 2px solid #9a9cb5;
    background: #fff url(../images/ico_check.png) no-repeat center center;
    box-sizing: border-box;
}

.form_wrap .check_wrap.error label:before {
    border-color: #b3261e;
}

.form_wrap .check_wrap input:checked~label:before {
    border-color: #743fa3;
    background-color: #743fa3;
    background-image: url(../images/icon_check_white.png);
}

.form_wrap .check_wrap .error_text{
    /* position: absolute;
    top: 100%;
    left: 0;
    right: 0; */
    margin-bottom: 0;
}

.form_wrap .btn_area {
    margin-top: 6.3rem;
}

.form_wrap .btn_area .btn_lg {
    width: 100%;
    font-size: 2.2rem;
}

.form_wrap ::-webkit-input-placeholder {
    color: #828282 !important;
}

.form_wrap ::-moz-placeholder {
    color: #828282 !important;
}

.form_wrap :-ms-input-placeholder {
    color: #828282 !important;
}

.form_wrap :-moz-placeholder {
    color: #828282 !important;
}

/* layer */
.layer_wrap {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.layer_wrap .layer_popup {
    width: 50rem;
    padding: 5.6rem;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform: translate(calc(-50% - .5px), calc(-50% - .5px));
    box-shadow: .4rem .4rem 0 0 #1dd1d3;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease, visibility .3s ease;
    z-index: 101;
}

.layer_wrap .layer_popup.open {
    opacity: 1;
    visibility: visible;
}

.layer_wrap .layer_popup .layer_head {
    margin-bottom: 6rem;
}

.layer_wrap .layer_popup .layer_head .heading {
    display: block;
    font-size: 3.4rem;
    line-height: 1.41;
    font-weight: 400;
    letter-spacing: -.1rem;
}

.layer_wrap .layer_popup .layer_head em {
    display: block;
    font-style: normal;
}

.layer_wrap .layer_popup .btn_close_popup {
    display: block;
    position: absolute;
    right: 3.2rem;
    top: 3.2rem;
    width: 4.8rem;
    height: 4.8rem;
    font-size: 0;
    text-indent: -9999px;
    background: url(../images/ico-close.png) no-repeat center center;
}

.layer_wrap .dim {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease, visibility .3s ease;
    background: rgba(0, 0, 0, .4);
    z-index: 99;
}

.layer_wrap .layer_popup.open~.dim {
    opacity: 1;
    visibility: visible;
}

.layer_wrap .layer_popup.alert_popup {
    width: 32rem;
    max-width: 88.89%;
    height: 18.8rem;
    padding: 0;
    background: #fff;
    box-shadow: none;
}

.layer_wrap .layer_popup.alert_popup .cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 13.2rem;
    font-size: 1.6rem;
    color: #444;
    text-align: center;
    line-height: 1.6;
    letter-spacing: -.05rem;
}

.layer_wrap .layer_popup.alert_popup .btn_area {
    display: flex;
    flex-direction: row nowrap;
}

.layer_wrap .layer_popup.alert_popup .btn_area .btn_alert {
    display: block;
    flex: 1;
    height: 5.6rem;
    text-align: center;
    line-height: 5.6rem;
    background: #743fa3;
    font-size: 1.4rem;
    color: #fff;
    width: 100%;
    cursor: pointer;
}

.YouTubePopUp-Wrap {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.YouTubePopUp-Content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 110rem;
}

.YouTubePopUp-Close {
    bottom: auto;
    top: calc(50% - 31rem - 5.6rem);
    width: 4.8rem;
    height: 4.8rem;
    background: url(../images/ico-close_white.png) no-repeat center center;
    background-size: 100%;
}

.YouTubePopUp-Content iframe {
    width: 100%;
    height: 62rem !important;
}

.off {
    color:#5F6268 !important;
}

#wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-width: 127.8rem;
    min-height: 100%;
    margin: 0 auto;
    background: #fff;
}

#container {
    flex: 1 1 auto;
    padding-top: 13.4rem;
    min-height: 500px;
}
#contact_layer {
    position: absolute;
    z-index: 99999;
}


.fixed {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.inner {
    position: relative;
    width: 127.8rem;
    margin: 0 auto;
}

[class*=section_]+[class*=section_] {
    margin-top: 9rem;
}

.section_top+[class*=section_] {
    margin-top: 8rem;
}

.bg_fe {
    background-color: #F7F7F7 !important;
}

.bg_fe footer {
    background-color: #F7F7F7 !important;
}

/************************ MAIN(S) ************************/
.visual_wrap .visual_inner {
    position: relative;
}

.visual_wrap .visual_inner .kv_bg {
    width: 100%;
    height: 77.4rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.visual_wrap .visual_inner .kv_text {
    position: absolute;
    bottom: 4.8rem;
    left: 50%;
    transform: translateX(-50%);
    height: 5.2rem;
    padding: 0 4rem;
    background: #111;
    line-height: 5.2rem;
    font-size: 2.6rem;
    color: #743fa3;
}

.visual_wrap .visual_inner .kv_text .txt {
    opacity: 0;
    visibility: hidden;
}

.visual_wrap .visual_inner .typingTxt {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    text-align: center;
}

.visual_wrap .movie_inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 77.4rem;
}

.visual_wrap .movie_inner .video_wrap {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.visual_wrap .movie_inner .video_wrap video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
}

.video_wrap [class*=swiper-img-] {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center top;
}

.video_wrap .swiper-img-1 {
    background-image: url(../images/top_img_1.png);
    /* animation: imgFade1 9s ease infinite; */
    opacity: 1;
    z-index: 2;
}

.video_wrap .swiper-img-2 {
    background-image: url(../images/top_img_2.png);
    /* animation: imgFade2 9s ease infinite; */
    opacity: 1;
    z-index: 1;
}

/* .video_wrap .swiper-img-3 {
    background-image: url(../images/top_img_3.png);
    animation: imgFade3 9s ease infinite;
    opacity: 1;
    z-index: 1;
} */

@keyframes imgFade1 {
    0% {
        opacity: 1;
    }

    20% {
        opacity: 1;
    }

    33% {
        opacity: 1;
    }

    53% {
        opacity: 0;
    }

    66% {
        opacity: 0;
    }

    86% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes imgFade2 {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    33% {
        opacity: 0;
    }

    53% {
        opacity: 1;
    }

    66% {
        opacity: 1;
    }

    86% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes imgFade3 {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    33% {
        opacity: 0;
    }

    53% {
        opacity: 0;
    }

    66% {
        opacity: 1;
    }

    86% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.visual_wrap .movie_inner .cont_box {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: 20rem;
    text-align: left;
    z-index: 2;
}

.visual_wrap .movie_inner .cont_box dl {
    margin-bottom: 7rem;
    color: #fff;
}

.visual_wrap .movie_inner .cont_box dl dt {
    display: block;
    margin-bottom: 7rem;
    font-size: 6.5rem;
    letter-spacing: -.3rem;
}

.visual_wrap .movie_inner .cont_box dl dd {
    font-size: 2.6rem;
    line-height: 1.6;
}
.visual_wrap .movie_inner .cont_box dl dd.cont_title {
    font-weight: 500;
}
.visual_wrap .movie_inner .cont_box dl dd.cont_title + dd{
    display: flex;
    gap: 2rem;
}
.visual_wrap .movie_inner .cont_box dl dd strong {
    font-weight: 700;
}

.visual_wrap .movie_inner .cont_box .btn_basic {
    padding: 0 6rem;
    border-radius: 100px;
    background: transparent;
    color: #fff;
    border-color: #fff;
}

.cont_box {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, .8) 100%);
    content: ' ';
    position: absolute;
    text-shadow: 0px 3px 6px #0006;
}

.section_intro {
    background: #743fa3;
}

:lang(zh-CN) .section_intro strong,
:lang(zh-CN) .section_intro span{
    word-break: break-all;
}

.section_intro .inner {
    padding: 6.1rem 0 6rem;
}

.section_intro .left {
    margin-bottom: 4rem;
    width: 68%;
}

.section_intro .left p {
    font-size: 3rem;
    line-height: 1.4;
    color: #1dd1d3;
}

.section_intro .left p+p {
    margin-top: 3rem;
}

.section_intro .left p strong {
    font-size: 3.4rem;
    font-weight: 700;
}

.section_intro .left .player {
    position: absolute;
    right: 10.6rem;
    top: 7rem;
    width: auto;
}

/* .section_intro .right {position: absolute; right: 10.6rem; top: 7rem; width: 10%;} */
.section_intro .left .player .thumb {
    display: block;
    position: relative;
    overflow: hidden;
    width: 19.2rem;
    margin-bottom: .8rem;
    padding-bottom: 58.3%;
}

.section_intro .left .player .thumb:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .2);
    transition: background .25s linear;
    z-index: 2;
}

.section_intro .left .player .thumb:after {
    content: "";
    display: block;
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    width: 2.8rem;
    height: 2.2rem;
    background: url(../images/icon_youtube_secondary.png) no-repeat center top;
    z-index: 2;
}

.section_intro .left .player .thumb:hover:before {
    background: rgba(0, 0, 0, .4);
}

.section_intro .left .player .thumb img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.section_intro .left .player .text {
    font-size: 1.8rem;
    line-height: 1.625;
    color: #1dd1d3;
    font-weight: 700;
}

.section_blog {
    position: relative;
    margin: 0 !important;
    padding-top: 5rem;
}

.section_blog:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 27.2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.16);
    background: #743fa3;
    z-index: 1;
}

.careers.section_blog:before {
    display: none;
}

.section_blog .inner {
    position: relative;
    z-index: 2;
}

.section_blog .h_title_box {
    margin-bottom: 3.2rem;
}

.section_blog .blog_items {
    overflow: hidden;
}

.section_blog ul {
    margin: 0 -2.85rem;
}

.section_blog .col3 {
    float: left;
    padding: 0 2.85rem;
}

.section_blog .item_inner {
    display: block;
}

.section_blog .thumb {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 51.54%;
    margin-bottom: 2rem;
}

.section_blog .thumb img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .3s ease;
}

.section_blog a:hover .thumb img {
    transform: scale(1.1);
}

.section_blog .info .tit {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: .4rem;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 7.4rem;
    line-height: 3.7rem;
    text-overflow: ellipsis;
    font-size: 2.6rem;
    color: #111;
    word-break: break-word;
    font-weight: 500;
}

.section_blog .info .desc {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: 7.8rem;
    line-height: 2.4rem;
    text-overflow: ellipsis;
    font-size: 1.6rem;
    color: #444;
}

.main .section_portfolio {
    margin-top: 7rem;
}

.section_portfolio .h_title_box {
    margin-bottom: 3.2rem;
}

.portfolio_list_wrap {
    position: relative
}

.main .portfolio_list_wrap {
    position: relative;
    overflow: hidden;
}

.portfolio_list_wrap .list_items {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    gap: 3.5rem 2.5rem;
}

.portfolio_list_wrap .portfolio_list_items .item {
    flex: none;
    width: 19.2rem;
}
@media (hover: hover) {

    .portfolio_list_items .item .card:hover .info {
        opacity: 1;
    }
}

.portfolio_list_wrap .item .card {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 150%;
    margin-bottom: 1.1rem;
    cursor: pointer;
}

.portfolio_list_wrap .item .card .thumb,
.portfolio_list_wrap .item .card .info {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: all .6s ease;
}

.portfolio_list_wrap .item .card .thumb img {
    width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.portfolio_list_wrap .item .info {
    display: flex;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 0;
    z-index: 2;
}

/* .portfolio_list_wrap .item .card:hover .info {opacity: 1;} */
.portfolio_list_wrap .next {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 4.5rem;
    width: 4.8rem;
    margin: 0;
    background: rgba(0, 0, 0, .4);
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    background-image: url(../images/btn_next.png);
    z-index: 10;
}

.portfolio_list_wrap .item .info .box {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    flex: 1;
    padding: 1.6rem 1.6rem 0;
    color: #fff;
    background: rgba(94, 46, 144, .9);
}

.portfolio_list_wrap .item .info .box .corp {
    display: block;
    margin-bottom: .6rem;
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: -.05rem;
}

.portfolio_list_wrap .item .info .box .title {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    width: 100%;
    height: 4.4rem;
    margin-bottom: 1.3rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-word;
    font-weight: 500;
    text-overflow: ellipsis;
    letter-spacing: -.05rem;
}

.portfolio_list_wrap .item .info .box .title.lg {
    display: block;
    font-size: 2.4rem;
    line-height: 1;
    white-space: nowrap;
}

.portfolio_list_wrap .item .info .box .mid {
    display: flex;
    flex-flow: column wrap;
}

.portfolio_list_wrap .item .info .box .type {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    height: 2.2rem;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
    font-size: 1.4rem;
    line-height: 1.57;
}

.portfolio_list_wrap .item .info .box .type:last-child {
    -webkit-line-clamp: 2;
    height: 4.4rem;
    margin-bottom: 1.2rem;
}

.portfolio_list_wrap .item .info .box .supports {
    display: flex;
    margin-top: .4rem;
    margin-bottom: 1.2rem;
}

.portfolio_list_wrap .item .info .box .supports [class*=icon_] {
    display: block;
    overflow: hidden;
    width: 1.8rem;
    height: 1.8rem;
    margin-right: .2rem;
    font-size: 0;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: center center;
}

.portfolio_list_wrap .item .info .box .supports .icon_ms {
    background-image: url(../images/ico-ms.svg);
}

.portfolio_list_wrap .item .info .box .supports .icon_apple {
    background-image: url(../images/ico-apple.svg);
}

.portfolio_list_wrap .item .info .box .supports .icon_playstation {
    background-image: url(../images/ico-playstation.svg);
}

.portfolio_list_wrap .item .info .box .supports .icon_google {
    background-image: url(../images/ico-google.svg);
}

.portfolio_list_wrap .item .info .box .supports .icon_xbox {
    background-image: url(../images/ico-xbox.svg);
}

.portfolio_list_wrap .item .info .box .supports .icon_steam {
    background-image: url(../images/ico-steam.svg);
}

/* 2022.12.13 icon 정리 */
.portfolio_list_wrap .item .info .box .supports .icon_mobile {
    background-image: url(../images/ico-mobile.svg);
}
.portfolio_list_wrap .item .info .box .supports .icon_console {
    background-image: url(../images/ico-console.svg);
}
.portfolio_list_wrap .item .info .box .supports .icon_pc {
    background-image: url(../images/ico-pc.svg);
}
.portfolio_list_wrap .item .info .box .supports .icon_mobile,
.portfolio_list_wrap .item .info .box .supports .icon_console,
.portfolio_list_wrap .item .info .box .supports .icon_pc {
    width: 24px;
    height: 24px;
    background-position: center;
}

.portfolio_list_wrap .item .info .box .bottom {
    border-top: 1px solid rgba(255, 255, 255, .35);
}

.portfolio_list_wrap .item .info .box .part {
    display: block;
    margin-bottom: 0.8rem;
    padding-top: 0.6rem;
    font-size: 1.5rem;
    line-height: 1.46;
    letter-spacing: -.11rem;
    /* white-space: nowrap; */
    word-break: break-all;
}

.portfolio_list_wrap .item .info .box ul {
    overflow: hidden;
    max-height: 5.4rem;
}

.portfolio_list_wrap .item .info .box ul li {
    font-size: 1.2rem;
    line-height: 1.5;
    display: flex;
}

/* .portfolio_list_wrap .item .info .box ul li:before {
    content: "-";
    display: inline-block;
    margin-right: .3rem;
} */

.section_portfolio .company {
    display: block;
    height: 3.4rem;
    text-align: center;
}

.section_portfolio .company img {
    width: auto;
    height: 3.4rem;
}

.section_portfolio .btn_area {
    margin-top: 8.8rem;
    text-align: center;
}

.portfolio_list_wrap.open .portfolio_list_items {
    margin: -4rem 0 0;
}

.portfolio_list_wrap.open .portfolio_list_items .list_items {
    flex-wrap: wrap;
    left: 0 !important;
}

.portfolio_list_wrap.open .portfolio_list_items .list_items .item {
    width: 19.2rem;
    margin: 4rem 0 0;
}

.portfolio_list_wrap.open .next {
    display: none;
}

.portfolio_list_wrap .list_open_type {
    margin: -4rem 0 0;
}

.portfolio_list_wrap .list_open_type .list_items {
    flex-wrap: wrap;
    overflow: hidden;
}

.portfolio_list_wrap.open .list_open_type .list_items {
    max-height: none;
}

/* .portfolio_list_wrap .list_open_type .list_items .item {width: 19.2rem; margin-top: 4rem;} */
/* .portfolio_list_wrap .list_open_type .item .card {width: 19.2rem; height: 28.8rem;} */




.m_portfolio_list_items .list_items {
    /* display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(190px, max-content));
    /* grid-gap: 16px; */
    /* justify-content: center; */
    padding: initial;
}
.m_portfolio_list_items.home_portfolio .list_items{
    justify-content: center;
}

.m_portfolio_list_items .item .none_active .info {
    opacity: 0;
}
.m_portfolio_list_items .item .card:hover .info {
    opacity: 1;
}

.font-weight-500 {
    font-weight: 500;
}

.width-100 {
    width: 100%;
}

.bd_basic {
    border: 1px solid #743FA3;
}
input {
    box-sizing: border-box;
    border: 1px solid #d5d3e4;
}

input:focus {
    box-sizing: border-box;
    outline: none;
    border-radius: 0;
    border: 2px solid #743FA3;
}

input:hover {
    box-sizing: border-box;
    /* outline-color:  #743FA3; */
    border: 2px solid #743FA3;
    border-radius: 0;
}
/************************ MAIN(E) ************************/

/************************ 반응형 1320px(S) ************************/
@media screen and (max-width: 1320px) {

    /* visible & hide */
    .visible_md {
        display: block !important;
    }

    .hidden_md {
        display: none !important;
    }

    /* float */
    .f_none_md {
        float: none !important;
    }

    /* layer */
    .YouTubePopUp-Content {
        width: calc(100% - 4.8rem);
        height: auto;
        padding-bottom: 56.36%;
    }

    .YouTubePopUp-Close {
        top: -5.6rem;
    }

    .YouTubePopUp-Content iframe {
        height: 100% !important;
    }

    #wrap {
        min-width: 100%;
    }

    .inner {
        width: 100%;
        padding: 0 2.4rem;
    }

    /* MAIN(S) */   
    .section_intro .inner {
        padding: 6.1rem 2.4rem 6rem;
    }

    .section_intro .left p {
        font-size: 2.6rem;
        line-height: 1.615;
    }

    .section_intro .left p strong {
        font-size: 3rem;
    }

    .section_blog {
        position: relative;
        margin: 0 !important;
        padding-top: 5rem;
    }

    .section_blog:before {
        height: auto;
        padding-bottom: 22.88%;
    }

    .section_blog ul {
        margin: 0 -1.2rem;
    }

    .section_blog .col3 {
        padding: 0 1.2rem;
    }
    /* MAIN(E) */
}
/************************ 반응형 1320px(E) ************************/


/************************ 반응형 992px(S) ************************/
@media screen and (max-width: 992px) {

    * {
        letter-spacing: -.05rem;
    }

    /* visible & hide */
    .visible_sm {
        display: block !important;
        word-wrap: anywhere;
    }

    .hidden_sm {
        display: none !important;
    }

    /* float */
    .f_none_sm {
        float: none !important;
    }

    /* column */
    .col1_sm {
        width: 100%;
    }

    .col2_sm {
        width: 50%;
    }

    .col3_sm {
        width: 33.33333333333333%;
    }

    .col4_sm {
        width: 25%;
    }

    .col5_sm {
        width: 20%;
    }

    /* text style */
    .h_title_box {
        margin-bottom: 2.4rem;
    }

    .h_title {
        font-size: 2.4rem;
        line-height: 1.4;
        word-break: break-word;
    }

    .h_title_box .desc {
        margin-top: 2.4rem;
        font-size: 1.6rem;
        line-height: 1.6;
    }

    .h_title_box.inner,
    .h_title_box.inner:last-child {
        margin: 0 auto 2.4rem;
    }

    .h_title_box:last-child {
        margin-bottom: 0;
    }

    .h_title_box:last-child .desc {
        padding-bottom: 0;
    }

    .cont+.h_title_box {
        margin-top: 4rem;
    }

    .s_title_box {
        margin: 4rem 0 2.4rem;
    }

    .s_title_box .s_title {
        display: block;
        font-size: 2rem;
        line-height: 1.4;
    }

    .s_title_box .btn_basic {
        margin-left: 0;
        margin-top: 1.6rem;
    }


    /* button */
    .btn_basic {
        height: 4rem;
        padding: 0 2rem;
        line-height: 3.8rem;
        letter-spacing: -.05rem;
    }

    button.btn_basic {
        line-height: 1;
    }

    .btn_lg {
        height: 5.6rem;
        padding: 0 2rem;
        line-height: 5.4rem;
        font-size: 1.6rem;
    }

    .btn_xl {
        min-width: 15.2rem;
        height: 4.8rem;
        padding: 0 1rem;
        line-height: 4.6rem;
        font-size: 1.6rem;
    }

    /* form */
    .form_group .form_input_box+.form_input_box {
        margin-top: .8rem;
    }

    .form_group .form_input_box .error_text {
        color: #b3261e;
        font-size: 1.4rem;
        line-height: 1.6;
    }

    .form_wrap .check_wrap label {
        font-size: 1.4rem;
        line-height: 1.6;
    }

    .form_wrap .btn_area {
        margin-top: 4rem;
    }

    .form_wrap .btn_area .btn_lg {
        width: 100%;
        font-size: 2.2rem;
    }

    .form_wrap ::-webkit-input-placeholder {
        color: #828282 !important;
    }

    .form_wrap ::-moz-placeholder {
        color: #828282 !important;
    }

    .form_wrap :-ms-input-placeholder {
        color: #828282 !important;
    }

    .form_wrap :-moz-placeholder {
        color: #828282 !important;
    }

    /* layer */
    .layer_wrap .layer_popup {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        padding: 6.4rem 0 0;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transform: translate(0, 100%);
        box-shadow: none;
        opacity: 1;
        visibility: visible;
        transition: transform .3s ease;
    }

    .layer_wrap .layer_popup.open {
        transform: translate(0, 0);
    }

    .layer_wrap .layer_popup_inner {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 2rem 3.4rem;
    }

    .layer_wrap .layer_popup .layer_head {
        margin-bottom: 4rem;
    }

    .layer_wrap .layer_popup .layer_head .heading {
        font-size: 2.4rem;
        line-height: 1.6;
        font-weight: 500;
        letter-spacing: -.05rem;
    }

    .layer_wrap .layer_popup .layer_body {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .layer_wrap .layer_popup .layer_body .form_wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .layer_wrap .layer_popup .layer_body .fieldset {
        flex: 1;
    }

    .layer_wrap .layer_popup .layer_body .btn_area {
        flex: none;
    }

    .layer_wrap .layer_popup .btn_close_popup {
        right: 1.2rem;
        top: 1.2rem;
        background-image: url(../images/ico-close_blk.png);
        background-size: 2.4rem;
    }

    /* layer */
    .YouTubePopUp-Wrap {
        background: #000;
    }

    .YouTubePopUp-Content {
        width: 100%;
    }

    .YouTubePopUp-Close {
        top: calc(50% - 50vh + .8rem);
        right: .8rem;
        background-size: 2.4rem;
    }

    .YouTubePopUp-Content iframe {
        height: 100% !important;
    }

    .layer_wrap .layer_popup.alert_popup {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: none;
    }

    #container {
        flex: 1 1 auto;
        padding-top: 6.4rem;
    }

    .inner {
        padding: 0 2rem;
    }

    [class*=section_]+[class*=section_] {
        margin-top: 6.4rem;
    }

    .section_top+[class*=section_] {
        margin-top: 4rem;
    }

    /* MAIN(S) */
    .visual_wrap .movie_inner .cont_box {
        text-align: center;
    }
    .visual_wrap .movie_inner .cont_box dl dd.cont_title + dd{
        justify-content: center;
    }

    .section_intro .inner {
        padding: 4rem 2rem;
    }

    .section_intro .left {
        margin-bottom: 4rem;
        padding-right: 24rem;
        width: 100%;
    }

    .section_intro .left p {
        font-size: 2rem;
        font-weight: 500;
    }

    .section_intro .left p+p {
        margin-top: 0;
    }

    .section_intro .left p strong {
        display: block;
    }

    .section_intro .left .player{
        right: 2rem;
    }

    .section_intro .left .player .thumb {
        width: 100%;
        margin-bottom: 1.6rem;
        padding-bottom: 57.8%;
    }

    .section_intro .left .player .thumb:after {
        right: 2rem;
        bottom: 1.6rem;
        width: 4.8rem;
        height: 4.8rem;
        background-image: url(../images/icon_youtube_secondary_96x96.png);
        background-size: 100%;
    }

    .section_intro .left .player .thumb img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .section_intro .left .player .text {
        font-size: 2rem;
        line-height: 1.4;
        text-align: center;
    }

    .section_blog {
        padding-top: 4rem;
    }

    .section_blog:before {
        padding-bottom: 56.1%;
    }

    .section_blog .h_title_box {
        margin-bottom: 2.4rem;
    }

    .section_blog ul {
        margin: 0;
    }

    .section_blog .col1_sm {
        float: none;
        padding: 0;
    }

    .section_blog .col1_sm+.col1_sm {
        margin-top: 4rem;
    }

    .section_blog .thumb {
        margin-bottom: 1.6rem;
    }

    .section_blog .info .tit {
        margin-bottom: .8rem;
        max-height: 5.6rem;
        line-height: 2.8rem;
        font-size: 2rem;
    }

    .main .section_portfolio {
        margin-top: 6.4rem;
    }

    .section_portfolio .h_title_box {
        margin-bottom: 2.4rem;
    }

    .section_portfolio .btn_area .btn_basic {
        padding: 0 7.8rem;
    }

    /* .portfolio_list_wrap .list_items {gap: 1rem;} */
    .section_portfolio .btn_area .btn_basic {
        padding: 0 7.8rem;
    }

    .portfolio_list_wrap.open .portfolio_list_items {
        margin: -4rem 0 0;
    }

    .portfolio_list_wrap.open .portfolio_list_items .list_items {
        flex-wrap: wrap;
        left: 0 !important;
    }

    .portfolio_list_wrap.open .portfolio_list_items .list_items .item {
        width: 19.2rem;
        margin: 4rem 0 0;
    }

    .portfolio_list_wrap.open .next {
        display: none;
    }

    .cont_portfolio .section_portfolio {
        margin-top: 8rem;
    }

    .video_wrap .swiper-img-1 {
        background-position: 40% top;
    }
    .video_wrap .swiper-img-2 {
        background-position: 34% top;
    }
    /* MAIN(E) */
    /* .m_portfolio_list_items .item .card:hover .info {
        opacity: 0;
    } */

    .m_portfolio_list_items .item .card.active .info {
        opacity: 1;
    }
    
}
/************************ 반응형 992px(E) ************************/

/************************ 반응형 767px(S) ************************/
@media screen and (max-width: 767px) {

    .visible_xs {
        display: block !important;
    }

    .hidden_xs {
        display: none !important;
    }

    .visual_wrap .visual_inner .kv_bg {
        height: auto;
        padding-bottom: 151.1%;
    }

    .visual_wrap .movie_inner {
        height: 50rem;
    }

    .video_wrap .swiper-img-1{
        position: relative;
    }

    .video_wrap .swiper-img-1::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
        z-index: 1;
    }

    .visual_wrap .movie_inner .cont_box dl {
        margin-bottom: 3.6rem;
        color: #fff;
    }

    .visual_wrap .movie_inner .cont_box dl dd {
        font-size: 2rem;
        line-height: 1.5;
        font-weight: 500;
    }

    .section_intro .inner{
        padding-bottom:56px;
    }
    
    .section_intro .left{
        margin-bottom: 28px;
        padding-right: 0;
    }

    .section_intro .left p {
        text-align: center;
    }

    .section_intro .left p strong{
        font-size: 2rem;
    }
    .section_intro .left .player{
        position: inherit;
        margin-top:24px;
        width: 100%;
    }
    .section_intro .btn_primary_linear{
        width: 100%;
    }
    .visual_wrap .movie_inner .cont_box dl dd.cont_title + dd{
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 20px;
    }
    .visual_wrap .movie_inner .cont_box dl dd.cont_title + dd .btn_home{
        margin-top: 0;
        width: 100%;
    }
}
/************************ 반응형 767px(E) ************************/

/************************ 반응형 459px(S) ************************/
@media screen and (max-width: 459px) {

    .visual_wrap .movie_inner .cont_box dl dd.cont_title + dd{
        flex-wrap: wrap;
        gap:12px;
        margin-top: 20px;
    }

    .visual_wrap .movie_inner .cont_box dl dd .btn_home {
        margin-top: 0;
        width: 100%;
    }

    .portfolio_list_wrap .list_items {
        display: flex;
        grid-template-columns: none;
        flex-flow: wrap;
        left: 0 !important;
        gap: 1.6rem;
    }

    .portfolio_list_wrap .list_items {
        display: flex;
        grid-template-columns: none;
        flex-flow: wrap;
        left: 0 !important;
        gap: 2.4rem 1.6rem;
        justify-content: flex-start;
    }

    .portfolio_list_wrap .m_portfolio_list_items .item {
        width: calc(50% - 1.8rem);
    }

    .portfolio_list_wrap .item .card {
        margin-bottom: .8rem;
        padding-bottom: 150%;
        width: 100%;
        height: auto;
    }

    .portfolio_list_wrap .next {
        display: none;
    }

    .portfolio_list_wrap .item .info .box {
        padding: 1.2rem 1.2rem 1rem;
    }

    .portfolio_list_wrap .item .info .box .corp {
        margin-bottom: .4rem;
        font-size: 1.1rem;
        line-height: 1.32rem;
        font-weight: 400;
        letter-spacing: -.05rem;
    }

    .portfolio_list_wrap .item .info .box .title {
        height: 3.8rem;
        margin-bottom: 1.2rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
    }

    .portfolio_list_wrap .item .info .box .title.lg {
        font-size: 2rem;
    }

    .portfolio_list_wrap .item .info .box .type {
        height: 1.4rem;
        font-size: 1.2rem;
        line-height: 1.4;
    }

    .portfolio_list_wrap .item .info .box .type:last-child {
        height: 2.8rem;
        margin-bottom: 1.8rem;
    }

    .portfolio_list_wrap .item .info .box .supports {
        margin-top: .4rem;
        margin-bottom: 1rem;
    }

    .portfolio_list_wrap .item .info .box .part {
        margin-bottom: 1rem;
        padding-top: 1rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
        letter-spacing: -.1rem;
    }

    .portfolio_list_wrap .item .info .box ul li {
        font-size: 1.1rem;
        line-height: 1.7rem;
    }

    .portfolio_list_wrap .item .info .box .bottom {
        border-top: 1px solid rgba(255, 255, 255, .35);
    }
}
/************************ 반응형 459px(E) ************************/

/************************ 반응형 399px(S) ************************/
@media screen and (max-width: 399px) {

    .section_portfolio .company {
        height: 2.7rem;
    }

    .section_portfolio .company img {
        height: 2.7rem;
    }

    .section_portfolio .btn_area {
        margin-top: 4em;
    }

    .section_portfolio .company {
        height: 2.7rem;
    }

    .section_portfolio .company img {
        height: 2.7rem;
    }

    .section_portfolio .btn_area {
        margin-top: 4rem;
    }

    .section_portfolio .btn_area .btn_basic {
        width: 100%;
    }

    .portfolio_list_wrap .list_open_type .list_items {
        gap: 2.6rem 1.6rem;
    }

    .portfolio_list_wrap.open .list_open_type .list_items {
        max-height: none;
    }

    .portfolio_list_wrap .list_open_type .list_items .item {
        width: calc(50% - .8rem);
    }

    .portfolio_list_wrap .list_open_type .item .card {
        width: 100%;
        height: auto;
        padding-bottom: 150%;
    }

    .cont_portfolio .section_portfolio {
        margin-top: 8rem;
    }

}
/************************ 반응형 399px(E) ************************/

@media screen and (max-width: 425px) {
    .error_page>* {
        max-width: 320px;
    }
}

@media screen and (max-width: 320px) {

    html,
    body {
        font-size: 9px;
    }
}