@charset "utf-8";

#footer {
    width: 100%;
    border-top: 1px solid #e1e1e1;
    background: #fff;
}
#footer .f_top {
    padding: 6rem 0 5rem;
}

#footer .d_flex {
    justify-content: space-between;
}

#footer .location {
    width: 35rem;
    flex: none;
}

#footer .location .title {
    display: block;
    margin-bottom: 1.8rem;
    font-size: 2rem;
    line-height: 1.6;
    color: #111;
    font-weight: 500;
}

#footer .location .con {
    font-size: 1.4rem;
    line-height: 1.57;
    letter-spacing: 0px;
}

#footer .location .con p:not(:last-child) {
    margin-bottom: 1.8rem;
}

#footer .f_nav_wrap {
    flex: 1;
}

#footer .nav_gnb .nav_list_items {
    display: flex;
    justify-content: flex-end;
}

#footer .f_nav_wrap .nav_list+.nav_list {
    margin-left: 5rem;
}

#footer .nav_gnb .nav_list .menu_dep1,
#footer .nav_gnb .nav_list .menu_dep2 a {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.5;
}

#footer .f_nav_wrap .nav_list .menu_dep2 {
    min-width: 8.5rem;
    margin-top: 1.2rem;
}

#footer .f_nav_wrap .nav_list .menu_dep2 a {
    font-size: 1.4rem;
    line-height: 2.14;
    white-space: nowrap;
    color: #444;
    font-weight: 400;
    letter-spacing: -.05rem;
}
#footer .f_nav_wrap .nav_list .menu_dep2 a.on {
    color: #743fa3;
}

@media (hover: hover) {
    #footer .f_nav_wrap .nav_list .menu_dep2 a:hover {
        color: #743fa3;
    }
}

#footer .f_bottom {
    padding: 2.4rem 0 16rem;
    border-top: 1px solid #e1e1e1;
}

#footer .f_bottom .copyright {
    font-size: 1.6rem;
    line-height: 1.625;
    font-weight: 500;
}

#footer .f_bottom .sns {
    font-size: 0;
}

#footer .f_bottom .sns a {
    display: inline-block;
    margin-left: 1.4rem;
    vertical-align: top;
}

#footer .f_bottom .sns a i {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: center center;
}
#footer .f_bottom .sns a.facebook {
    display: none;
}

:lang(ko) #footer .f_bottom .sns a.facebook {
    display: inline-block;
}

#footer .f_bottom .sns a i.facebook {
    background-image: url(../images/ico-facebook.svg);
}

#footer .f_bottom .sns a i.youtube {
    background-image: url(../images/ico-youtube.svg);
}




/************************ 반응형 1320px(S) ************************/
@media screen and (max-width: 1320px) {

    #footer .inner {
        padding: 0 3.2rem;
    }

    #footer .f_nav_wrap .nav_list+.nav_list {
        margin-left: 4.71vw;
    }
}
/************************ 반응형 1320px(E) ************************/

/************************ 반응형 992px(S) ************************/
@media screen and (max-width: 992px) {

    #footer .f_top {
        padding: 3.8rem 0 4rem;
    }

    #footer .inner {
        padding: 0 2rem;
    }

    #footer .d_flex {
        flex-direction: column;
        justify-content: flex-start;
    }

    #footer .location {
        width: 100%;
    }

    #footer .location .title {
        margin-bottom: 1.6rem;
        font-size: 1.4rem;
        line-height: 1.57;
    }

    #footer .location .con {
        font-size: 1.2rem;
        line-height: 1.5;
    }

    #footer .location .con p:not(:last-child) {
        margin-bottom: 1.6rem;
    }

    #footer .f_nav_wrap {
        width: 100%;
        margin-top: 4rem;
        flex: none;
        overflow: hidden;
    }

    #footer .nav_gnb .nav_list_items {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;
        margin: 0 -.85rem;
        margin-left: 0;
        line-height: 1.6;
    }
    #footer .nav_gnb .nav_list_items .menu_dep1 {
        font-size: 1.6rem;
        line-height: 2.6;
        font-weight: 700;
    }
    #footer .nav_gnb .nav_list_items .menu_dep2 {
        display: block;
        font-size: 1.4rem;
        margin-top: 0.8rem;
        margin-bottom: 0;
    }
    #footer .nav_gnb .nav_list_items .menu_dep2 a {
        color: #828282!important;
        line-height: 1.8;
    }
    #footer .nav_gnb .nav_list_items .menu_dep2 a.on {
        color: #743fa3!important;
    }
  
   

    #footer .f_nav_wrap {
        margin-top: auto;
    }
    #footer .f_nav_wrap .nav_list {
        margin: 0 .85rem;
    }

    #footer .f_nav_wrap .nav_list+.nav_list {
        margin: 0 .85rem;
    }

    #footer .nav_gnb .nav_list .menu_dep1,
    #footer .nav_gnb .nav_list .menu_dep2 a {
        font-size: 1.4rem;
        line-height: 1.6;
    }

    #footer .f_bottom {
        padding: 2.4rem 0 4.2rem;
    }

   
    #footer .f_bottom .copyright {
        font-size: 1.2rem;
        line-height: 1.5;
    }
    
    #footer .f_bottom .sns {
        transform: translateY(4rem);
        position: relative;
        z-index: 1;
    }

    #footer .f_bottom .sns a {
        margin-left: .5rem;
    }

    
}
/************************ 반응형 992px(E) ************************/