@charset "utf-8";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video {margin: 0; padding: 0; border: 0; outline: 0; font-size: 100%; vertical-align: baseline; background: transparent; box-sizing: border-box;}
html, body {width: 100%; height: 100%; font-size: 10px; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-smooth: antialiased; text-rendering: optimizeLegibility;}

body {color: #444; line-height: 1; letter-spacing: -1px;}




article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {display: block;}
ol, ul, li {list-style: none;}
blockquote,q {quotes: none;}
blockquote:before,blockquote:after,q:before,q:after {content: ''; content: none;}
a {margin: 0; padding: 0; font-size: 100%; color: #111; vertical-align: baseline; background: transparent; outline: none; box-sizing: border-box;}
a:hover, a:visited, a:active, a:link {text-decoration: none;}
del {text-decoration: line-through;}
abbr[title],dfn[title] {border-bottom: 1px dotted; cursor: help;}
table {border-collapse: collapse; border-spacing: 0;}
input,select, optgroup {vertical-align: middle; box-shadow: none; border-radius: 0;}
textarea {resize: none; overflow-y: auto;}
[type="checkbox"],[type="radio"] {padding: 0; box-sizing: border-box;}
button, select {text-transform: none;}
button,[type="button"],[type="reset"],[type="submit"] {-webkit-appearance: button; cursor: pointer; border: 0; background: none;}
button:disabled,[type="button"]:disabled,[type="reset"]:disabled,[type="submit"]:disabled {cursor: default;}
button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {padding: 0; border-style: none;}
button:-moz-focusring,[type="button"]:-moz-focusring,[type="reset"]:-moz-focusring,[type="submit"]:-moz-focusring {outline: none;}
/* button:active, button:active > * {-ms-transform:translate(-1px, -1px);}
button:active:before, button:active:after {-ms-transform:translate(1px, 1px);} */
[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button {height: auto; -webkit-appearance: none;}
[type="search"] {-webkit-appearance: textfield; outline-offset: -2px;}
[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
::-webkit-file-upload-button {-webkit-appearance: button; font: inherit;}
/* select {-webkit-appearance: none;}
select::-ms-expand {display: none;} */
label {vertical-align: middle; cursor: pointer;}
fieldset {border: 0;}
a, img {outline: none;}
img {vertical-align: top; transform: translateZ(0); backface-visibility: hidden; image-rendering: -webkit-optimize-contrast;}




