@charset "utf-8";

/************************ floating_btn_wrap(S) ************************/
.floating_btn_wrap {
    position: fixed;
    bottom: 2.4rem;
    right: 2rem;
    z-index: 97;
}

.floating_btn_wrap.pos_ab {
    position: absolute;
}

.floating_btn_wrap .floating_btn {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 2.4rem;
    background: linear-gradient(to bottom, #c273de, #874bbd);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    transition: width 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.floating_btn_wrap .floating_btn .btn_inner {
    display: block;
    position: relative;
    overflow: hidden;
    width: 4.8rem;
    height: 4.8rem;
    line-height: 4.8rem;
    border-radius: 2.4rem;
    transition: width 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    white-space: nowrap;
}

.floating_btn_wrap .floating_btn .icon {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.8rem;
    height: 4.8rem;
    background: url(../images/icon_inquiry.png) no-repeat center center;
    background-size: 2.4rem;
}

.floating_btn_wrap .floating_btn .text {
    display: inline-block;
    padding: 0 0 0 4.8rem;
    font-weight: 700;
    font-size: 1.4rem;
    letter-spacing: -.05rem;
    color: #fff;
    opacity: 0;
    transition: opacity 1s ease;
}

.floating_btn_wrap.pos_ab .floating_btn {
    width: 19.5rem;
}

.floating_btn_wrap.pos_ab .floating_btn .btn_inner {
    overflow: visible;
    width: 19.5rem;
}

.floating_btn_wrap.pos_ab .floating_btn .text {
    opacity: 1;
}
/************************ floating_btn_wrap(E) ************************/

/************************ services_box(S) ************************/
.services_box {
    display:flex;
    justify-content: space-between;
}
.services_box .box{
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 200px;
    height: 336px;

}

.services_box .box .team_name{
    display:flex;
    align-items:center;
    margin-bottom:4px;
    padding:0;
    min-height:30px;
    font-size:1.4rem;
    color:#5F6268;
}

.services_box .box .title{
    margin-bottom:24px;
    font-size:1.8rem;
    font-weight:700;
    line-height:140%;
    text-align:center;
    color:#743FA3;
    transition: color .3s;
}
.services_box .box .title:lang(ja){
    word-break: break-all;
}

.services_box .box .img_box{
    display: block;
    margin-top:auto;
    margin-bottom: 32px;
    width: 160px;
    height: 160px;
    transition: background .3s;
}

.services_box .box.ll .img_box{
    background: url(../images/ill_ll_services_off.png) no-repeat center center;
}
.services_box .box.ps .img_box{
    background: url(../images/ill_ps_services_off.png) no-repeat center center;
}
.services_box .box.cm .img_box{
    background: url(../images/ill_cm_services_off.png) no-repeat center center;
}
.services_box .box.qa .img_box{
    background: url(../images/ill_qa_services_off.png) no-repeat center center;
}
.services_box .box.gs .img_box{
    background: url(../images/ill_gs_services_off.png) no-repeat center center;
}
.services_box .box.cp .img_box{
    background: url(../images/ill_cp_services_off.png) no-repeat center center;
}

.services_box .box > img{
    margin-top:auto;
    margin-bottom: 32px;
}

.services_box .box > button{
    margin-top:auto;
    width:180px;
    height:36px;
}
.services_box .box > button:hover{
    color:#fff !important;
}

.services_box .box.active .title{
    color:#743FA3 !important;
}
.services_box .box.ll.active .img_box{
    background-image: url(../images/ill_ll_services_on.png);
}
.services_box .box.ps.active .img_box{
    background-image: url(../images/ill_ps_services_on.png);
}
.services_box .box.cm.active .img_box{
    background-image: url(../images/ill_cm_services_on.png);
}
.services_box .box.qa.active .img_box{
    background-image: url(../images/ill_qa_services_on.png);
}
.services_box .box.gs.active .img_box{
    background-image: url(../images/ill_gs_services_on.png);
}
.services_box .box.cp.active .img_box{
    background-image: url(../images/ill_cp_services_on.png);
}
/* .services_box .box.active button{
    color:#fff !important;
} */
/************************ services_box(E) ************************/

/************************ section_top(S) ************************/
.section_top .page_cover {
    height: 50.4rem;
    padding-top: 6.7rem;
    color: #fff;
    background: #743fa3;
}

.section_top .page_cover .cont_title {
    margin-bottom: 1.6rem;
    font-size: 5.6rem;
    font-weight: 700;
    line-height: 1.39;
    letter-spacing: -.2rem;
    white-space: nowrap;
}

.section_top .page_cover .desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.4rem;
    line-height: 1.5;
}

.section_top .page_cover .desc strong {
    font-weight: 500;
}

:lang(ja) .section_top .page_cover .desc strong{
    font-weight: 600;
}

.section_top .tab_wrap {
    position: relative;
    width: 100%;
    height: 32rem;
}

.section_top .tab_wrap:before,
.section_top .tab_wrap:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    left: calc((100% - 127.8rem) / 2);
    top: 0;
}

.section_top .tab_wrap:before {
    height: 32rem;
    background: #111;
    z-index: 1;
}

.section_top .tab_wrap:after {
    height: 25.6rem;
    background: #111;
    z-index: 1;
}

.section_top .tab_box {
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 192rem;
    margin: -19.8rem auto 0;
    z-index: 3;
}

.section_top .tab_list {
    display: flex;
    justify-content: flex-start;
    width: calc(100% - (100% - 127.8rem) / 2);
    margin: 0;
}

.section_top .tab_list li {
    width: 39.5rem;
    cursor: pointer;
}

.section_top .tab_list li.col1 {
    width: 100% !important;
}

.section_top .tab_list li > span {
    display: block;
    background: #111;
}

.section_top .tab_list li span .thumb {
    display: block;
    width: 100%;
    height: 25.6rem;
    background-position: center center;
    background-size: cover;
    transition: background .4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.section_top .tab_list .text {
    display: block;
    height: 6.4rem;
    padding: 1.7rem 2.4rem 0;
    color: #5f6268;
    font-size: 1.8rem;
    line-height: 1.67;
    font-weight: 700;
    transition: all .3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.section_top .portfolio_tab .tab_list .text:first-child {
    padding-top:0;
    display: flex !important;
    align-items: center;
}
.section_top .portfolio_tab .tab_box_mo{
    display: none;
}

.section_top .tab_list span:hover .text,
.section_top .tab_list .on span .text {
    height: 8.6rem;
    color: #1dd1d3;
    background: #743fa3;
}

.section_top .text_tab .tab_list {
    padding-top: 25.6rem;
    background-position: left top;
    background-repeat: no-repeat;
}

.section_top .tab_list li {
    width: 50%;
}

.section_top .tab_list li.col1 {
    width: 100% !important;
}

.section_top .tab_list li a {
    display: block;
    background: #111;
}

.section_top .tab_list .btn-filter .gs {
    padding: 0rem 2.4rem 0;
    line-height: 1.5
}

.section_top .tab_list li a .thumb {
    display: block;
    width: 100%;
    height: 25.6rem;
    background-position: center center;
    background-size: cover;
    transition: background .4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}


.section_top .tab_list li:nth-child(1) a .thumb {
    background-image: url(../images/tab_img_01_on.png);
    filter: grayscale(1);
    transition: filter .3s;
}

.section_top .tab_list li:nth-child(1).on a .thumb,
.section_top .tab_list li:nth-child(1) a .thumb:hover {
    background-image: url(../images/tab_img_01_on.png);
    filter: none;
}

.section_top .tab_list li:nth-child(2) a .thumb {
    background-image: url(../images/tab_img_02_on.png);
    filter: grayscale(1);
    transition: filter .3s;
}
.section_top .tab_list li:nth-child(2).on a .thumb,
.section_top .tab_list li:nth-child(2) a .thumb:hover {
    background-image: url(../images/tab_img_02_on.png);
    filter: none;
}

.section_top .tab_list li:nth-child(3) a .thumb {
    background-image: url(../images/tab_img_03_on.png);
    filter: grayscale(1);
    transition: filter .3s;
}
.section_top .tab_list li:nth-child(3).on a .thumb,
.section_top .tab_list li:nth-child(3) a .thumb:hover {
    background-image: url(../images/tab_img_03_on.png);
    filter: none;
}

.section_top .tab_list li:nth-child(4) a .thumb {
    background-image: url(../images/tab_img_04_on.png);
    filter: grayscale(1);
    transition: filter .3s;
}
.section_top .tab_list li:nth-child(4).on a .thumb,
.section_top .tab_list li:nth-child(4) a .thumb:hover {
    background-image: url(../images/tab_img_04_on.png);
    filter: none;
}

.section_top .tab_list li:nth-child(5) a .thumb {
    background-image: url(../images/tab_img_07_on.png);
    filter: grayscale(1);
    transition: filter .3s;
}
.section_top .tab_list li:nth-child(5).on a .thumb,
.section_top .tab_list li:nth-child(5) a .thumb:hover {
    background-image: url(../images/tab_img_07_on.png);
    filter: none;
}

.section_top .tab_list li:nth-child(6) a .thumb {
    background-image: url(../images/tab_img_08_on.png);
    filter: grayscale(1);
    transition: filter .3s;
}
.section_top .tab_list li:nth-child(6).on a .thumb,
.section_top .tab_list li:nth-child(6) a .thumb:hover {
    background-image: url(../images/tab_img_08_on.png);
    filter: none;
}

.section_top_services .tab_list .text {
    display: block;
    height: 6.4rem;
    /* padding: 1.7rem 1.2rem 0; */
    padding: 0 1.2rem;
    color: #5f6268;
    font-size: 1.8rem;
    line-height: 1.67;
    font-weight: 700;
    transition: all .3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.section_top_services .tab_list .text > span {
    display: flex;
    align-items: center;
    height: 64px;
    font-size: 1.7rem;
}

.section_top_services .tab_list a:hover .text,
.section_top_services .tab_list .on a .text {
    height: 8.6rem;
    color: #1dd1d3;
    background: #743fa3;
}
.section_top_services .tab_box_mo{
    display: none;
}

.careers.section_top .tab_list li:first-child a .thumb {
    background-image: url(../images/tab_img_05.png);
    background-position: right;
}

.careers.section_top .tab_list li:nth-child(2) a .thumb {
    background-image: url(../images/tab_img_06.png);
}

.careers.section_top .tab_list li:first-child a:hover .thumb,
.careers.section_top .tab_list li:first-child.on a .thumb {
    background-image: url(../images/tab_img_05_on.png);
    background-size: cover;
    background-position: right;
}

.careers.section_top .tab_list li:nth-child(2) a:hover .thumb,
.careers.section_top .tab_list li:nth-child(2).on a .thumb {
    background-image: url(../images/tab_img_06_on.png);
    background-size: cover;
}

.careers.section_top .tab_list a:hover .text,
.careers.section_top .tab_list .on a .text {
    height: 8.6rem;
    color: #1dd1d3;
    background: #743fa3;
}
.cont_contact .section_top .tab_wrap.text_tab:after {
    background-color: #4b505e;
}

.cont_contact .section_top .text_tab .tab_list {
    background-image: url(../images/img_contact_tab.png);
}

.cont_contact .section_top .tab_list span:hover .text,
.cont_contact .section_top .tab_list .on span .text {
    height: 64px;
    background: #111;
}

.cont_portpolio .section_top .tab_wrap.text_tab:after {
    background-color: #4b505e;
}

.cont_portpolio .section_top .text_tab .tab_list {
    background-image: url(../images/img_portfolio_tab.png);
}
/************************ section_top(E) ************************/

/************************ cont_portfolio(S) ************************/
.cont_portfolio .section_portfolio {
    margin-top: 13rem;
}
.cont_portpolio .section_portfolio {
    margin-top: 10.2rem;
}

.cont_portpolio .section_portfolio .btn_area {
    margin-top: 9rem;
}
.cont_portfolio .section_top .tab_wrap.text_tab:after {
    background-color: #4b505e;
}

.cont_portfolio .section_top .text_tab .tab_list {
    padding-top: 246px;
}

.cont_portfolio .section_top .text_tab .tab_list.all {
    background-image: url(../images/img_portfolio_tab_all.png);
}

.cont_portfolio .section_top .text_tab .tab_list.ll {
    background-image: url(../images/img_portfolio_tab_ll.png);
}

.cont_portfolio .section_top .text_tab .tab_list.ps {
    background-image: url(../images/img_portfolio_tab_ps.png);
}

.cont_portfolio .section_top .text_tab .tab_list.cm {
    background-image: url(../images/img_portfolio_tab_cm.png);
}

.cont_portfolio .section_top .text_tab .tab_list.qa {
    background-image: url(../images/img_portfolio_tab_qa.png);
}

.cont_portfolio .section_top .text_tab .tab_list.gs {
    background-image: url(../images/img_portfolio_tab_gs.png);
}

.cont_portfolio .section_top .text_tab .tab_list.cp {
    background-image: url(../images/img_portfolio_tab_cp.png);
}
/************************ cont_portfolio(E) ************************/

/************************ table_type(S) ************************/
.table_type {
    width: 100%;
    table-layout: fixed;
    border-top: 2px solid #111;
}

.table_type thead th {
    padding: 1.6rem;
    text-align: center;
    font-size: 1.8rem;
    line-height: 1.67;
    font-weight: 700;
    color: #111;
    border-bottom: 1px solid #e1e1e1;
    background: #fafafB;
}

.table_type tbody td {
    padding: 2.4rem;
    text-align: left;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.67;
    color: #111;
    vertical-align: middle;
    border-bottom: 1px solid #e1e1e1;
    background: #fff;
}

.table_type tbody td:not(:first-child) {
    border-left: 1px solid #e1e1e1;
}

.table_type tbody td.v_align_top {
    vertical-align: top;
}

.ps_table.flex_box {
    margin-top: 9rem;
    justify-content: space-between;
}

.ps_table.flex_box .item {
    text-align: center;
}

.ps_table.flex_box .img {
    display: block;
    margin-bottom: 4rem;
}

.ps_table.flex_box .img img {
    /* width: 100%; */
}

.ps_table.flex_box .text {
    font-size: 2rem;
    line-height: 160%;
}
/************************ table_type(E) ************************/

/************************ progress_box(S) ************************/
.flex_box {
    display: flex;
    flex-wrap: nowrap;
}

.progress_box .flex_box {
    padding: 0 4.2rem;
}

.progress_box .flex_box>li {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
}

.progress_box .flex_box>li+li {
    margin-left: 7.4rem;
}

.progress_box .flex_box>li+li:before {
    content: "";
    display: block;
    position: absolute;
    left: -6.9rem;
    top: 50%;
    width: 6.4rem;
    height: .5rem;
    background: url(../images/step_dot.png) repeat-x left top;
}

.progress_box .flex_box .prog_head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.6rem;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background: #743fa3;
}
:lang(ja) .progress_box .flex_box .prog_head {
    word-break: break-all;
    line-height: 1.1;
}

:lang(en) .progress_box .flex_box .prog_head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.6rem;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background: #743fa3;
}

.progress_box .flex_box .prog_body {
    flex: 1;
    padding: 3.6rem 2rem;
    text-align: center;
    background: #f5f5fB;
}

.progress_box .flex_box .prog_body .prog_con+.prog_con {
    margin-top: 3rem;
}

.progress_box .flex_box .prog_body .prog_con .tit,
.progress_box .flex_box .prog_body .prog_con .con {
    display: block;
    font-size: 1.7rem;
    line-height: 1.529;
}

.progress_box .flex_box .prog_body .prog_con .tit {
    font-weight: 600;
}

.progress_box.sm .flex_box {
    padding: 0 6.1rem;
}

.progress_box.sm .flex_box>li+li {
    margin-left: 3.9rem;
}

.progress_box.sm .flex_box>li+li:before {
    left: -3.4rem;
    width: 2.9rem;
}

.section_progress .h_title_box {
    margin-bottom: 1.6rem;
}

.section_progress .h_title_box.mgb9 {
    margin-bottom: 9rem;
}

.section_progress .step_list_wrap .right {
    padding-left: 60.3rem;
}

.section_progress .step_list_wrap li[class*=step] {
    position: relative;
    padding-bottom: 7.9rem;
}

.section_progress .step_list_wrap li[class*=step]+li[class*=step] {
    margin-top: 2.5rem;
}

.section_progress .step_list_wrap li[class*=step]:before {
    content: "";
    display: block;
    position: absolute;
    top: 10.6rem;
    bottom: 7.9rem;
    width: 1rem;
    background: url(../images/dot_vertical.png) repeat-y left top;
}

.section_progress .step_list_wrap li.left[class*=step]:before {
    left: 3.8rem;
}

.section_progress .step_list_wrap li.right[class*=step]:before {
    left: 64.1rem;
}

.section_progress .step_list_wrap li[class*=step] .bottom_line {
    position: absolute;
    left: 3.8rem;
    bottom: 0;
    width: 60.5rem;
    height: 7.9rem;
    background: url(../images/dot_horizontal_1.png) repeat-x left 6.5rem center;
    background-size: auto .5rem;
}

.section_progress .step_list_wrap li[class*=step] .bottom_line:before,
.section_progress .step_list_wrap li[class*=step] .bottom_line:after {
    content: "";
    display: block;
    position: absolute;
    width: 5.9rem;
    height: 4.2rem;
}

.section_progress .step_list_wrap li[class*=step].left .bottom_line:before {
    left: 1px;
    top: 0;
    background: #fff url(../images/dot_left_bottom.png) no-repeat left top;
    background-size: 100%;
}

.section_progress .step_list_wrap li[class*=step].left .bottom_line:after {
    right: 0;
    bottom: 1px;
    background: #fff url(../images/dot_right_bottom.png) no-repeat left top;
    background-size: 100%;
}

.section_progress .step_list_wrap li[class*=step].right .bottom_line:before {
    left: 0;
    bottom: 0;
    background: #fff url(../images/dot_left_top.png) no-repeat left top;
    background-size: 100%;
}

.section_progress .step_list_wrap li[class*=step].right .bottom_line:after {
    right: -2px;
    top: 2px;
    background: #fff url(../images/dot_right_top.png) no-repeat left top;
    background-size: 100%;
}

.section_progress .step_list_wrap li.right.last .bottom_line {
    left: 31.8rem;
    top: 60.9rem;
    width: 33rem;
    height: 12.8rem;
    background: url(../images/dot_horizontal_bottom.png) no-repeat center top white;
}

.section_progress .step_list_wrap li.right.last .bottom_line:before,
.section_progress .step_list_wrap li.right.last .bottom_line:after {
    display: none;
}

.section_progress .step_list_wrap .h_title {
    position: relative;
    padding-left: 10.6rem;
}

.section_progress .step_list_wrap .h_title .num {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: .8rem;
    width: 7.8rem;
    height: 7.8rem;
    color: #fff;
    font-size: 0;
    text-indent: -9999px;
    line-height: 7.8rem;
    text-align: center;
    background: #743fa3;
    box-shadow: .4rem .4rem #1dd1d3;
    font-weight: 400;
}

.section_progress .step_list_wrap .h_title .num:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 3.6rem;
}

.section_progress .step_list_wrap .step1 .h_title .num:after {
    background-image: url(../images/icon_num_1.png);
}

.section_progress .step_list_wrap .step2 .h_title .num:after {
    background-image: url(../images/icon_num_2.png);
}

.section_progress .step_list_wrap .step3 .h_title .num:after {
    background-image: url(../images/icon_num_3.png);
}

.section_progress .step_list_wrap .step4 .h_title .num:after {
    background-image: url(../images/icon_num_4.png);
}

.section_progress .step_list_wrap .step5 .h_title .num:after {
    background-image: url(../images/icon_num_5.png);
}

.section_progress .step_list_wrap .step6 .h_title .num:after {
    background-image: url(../images/icon_num_6.png);
}

.section_progress .step_list_wrap .h_title .en {
    display: block;
    font-size: 3.2rem;
    line-height: 1.3125;
}

.section_progress .step_list_wrap .step_cont {
    padding-left: 10.6rem;
}

.section_progress .step_cont .text {
    margin-bottom: 1.6rem;
    font-size: 1.7rem;
    line-height: 1.76;
    max-width: 550px;
}
:lang(zh-CN) .section_progress .step_cont .text,
:lang(ja) .section_progress .step_cont .text {
    word-break: break-word;
}


.section_progress .step_cont li {
    font-size: 1.5rem;
    line-height: 2.13;
    color: #111;
    font-weight: 500;
}

:lang(ja) .section_progress .step_cont li{
    font-weight: 600;
}

.section_progress .step_cont li:before {
    content: "-";
    display: inline-block;
    margin-right: .8rem;
}

.section_progress .toggle_box {
    position: relative;
    padding: 3.8rem 0 0 10.6rem;
}

.section_progress .toggle_box .box_tit {
    display: inline-block;
    position: relative;
    z-index: 2;
}

.section_progress .toggle_box .box_tit:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -.1rem;
    right: -.4rem;
    bottom: -.3rem;
    border: .6rem solid #1dd1d3;
    z-index: 1;
}

.section_progress .toggle_box .box_tit strong {
    display: inline-flex;
    align-items: center;
    position: relative;
    min-height: 4.4rem; 
    padding: 0.5rem 1.6rem;
    line-height: 2.2rem;
    color: #743fa3;
    font-size: 1.6rem;
    font-weight: 700;
    border: 2px solid #743fa3;
    z-index: 2;
}

.section_progress .toggle_box .cont {
    position: absolute;
    top: 2rem;
    left: 9.4rem;
    width: 53.8rem;
    padding: 6.6rem 2.8rem 2.8rem;
    background: #743fa3;
    box-shadow: .4rem .4rem #1dd1d3;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s ease, visibility .4s ease;
    z-index: 1;
}

.section_progress .toggle_box .cont ul {
    padding-top: 1.2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
}

.section_progress .toggle_box .cont ul li {
    position: relative;
    padding-left: 1.6rem;
    font-size: 1.4rem;
    line-height: 1.57;
    color: #fff;
    font-weight: 400;
}

.section_progress .toggle_box .cont ul li+li {
    margin-top: 1.2rem;
}

.section_progress .toggle_box .cont ul li:before {
    content: "-";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

.section_progress .toggle_box .btn_area {
    margin-top: 2.4rem;
    text-align: right;
}

.section_progress .toggle_box .btn_area .btn_basic {
    min-width: 19rem;
    font-weight: 500;
    border: 0;
}

.section_progress .toggle_box .btn_area .btn_basic:hover {
    background: #1dd1d3;
    color: #743fa3;
}


.section_progress .toggle_box.on .box_tit:before {
    display: none;
}

.section_progress .toggle_box.on .box_tit strong {
    color: #fff;
    border-color: transparent;
}

.section_progress .toggle_box.on .cont {
    opacity: 1;
    visibility: visible;
}

.section_progress .step_list_wrap li.step1 {
    padding-bottom: 7.8rem;
    background: url(../images/bg_step1.png) no-repeat left 67.4rem top 3.6rem;
}

.section_progress .step_list_wrap li.step2 {
    padding-bottom: 8rem;
    background: url(../images/bg_step2.png) no-repeat left 5rem top 6.1rem;
}

.section_progress .step_list_wrap li.step3 {
    padding-bottom: 8.6rem;
    background: url(../images/bg_step3.png) no-repeat left 60.6rem top 18.8rem;
}

.section_progress .step_list_wrap li.step4 {
    padding-bottom: 6.3rem;
    background: url(../images/bg_step4.png) no-repeat left 7.6rem bottom 17.7rem;
}



.section_progress .step_list_wrap li.step1 .step_cont {
    padding-bottom: 5.8rem;
}

.section_progress .step_list_wrap li.step2 .toggle_box {
    padding-bottom: 2.5rem;
}

.section_progress .step_list_wrap li.step3 .toggle_box {
    padding-bottom: 5.9rem;
}

.section_progress .step_list_wrap li.step4 .toggle_box {
    padding-bottom: 5.4rem;
}
/************************ progress_box(E) ************************/

/************************ section_why(S) ************************/
.section_why {
    overflow: hidden;
}

[class*=section]+.section_why {
    margin-top: 9rem;
}

.main [class*=section]+.section_why {
    margin-top: 7.3rem;
}

.section_why .h_title_box {
    margin-bottom: 3.4rem;
}

.section_why .cont_box_wrap {
    display: flex;
    flex-wrap: wrap;
    margin: -3rem -1.5rem 0;
}

.section_why .cont_box_wrap .box[class*=col] {
    display: flex;
    padding: 0rem 1.5rem 0;
}

.section_why .cont_box_wrap .box_in {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 4rem 0rem 3.2rem;
    border-bottom: 1px solid #E1E1E1;
}

.box .box_in.cp {
    border-bottom: none;
}

.section_why .cont_box_wrap .box_in::after {
    content: "";
    display: block;
    position: absolute;
    top: 4rem;
    right: 0;
    width: 21rem;
    height: 21rem;
    /* width:212px;
    height:210px; */
    background-repeat: no-repeat;
    background-position: center bottom;
}

.services_desc {
    letter-spacing: -.18rem;
    color:#111;
}
:lang(en) .services_desc {
    letter-spacing: 0px;
}

.team_name {
   font-size: 1.4rem;
   font-weight: 400;
   line-height: 30px;
   /* padding: 0 0 1rem 0; */
}

.section_why .cont_box_wrap .box_in .heading {
    position: absolute;
    top: 0;
    left: 5rem;
    height: 4.8rem;
    padding: 0 3rem;
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    color: #fff;
    line-height: 4.8rem;
    background: #743fa3;
}

.section_why .cont_box_wrap .box_in .cont {
    flex: 1;
}

.section_why .cont_box_wrap .box_in .title {
    display: block;
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.4;
    color: #743fa3;
}

.section_why .cont_box_wrap .box_in .desc {
    margin-bottom: 3.2rem;
    font-size: 1.6rem;
    line-height: 1.625;
}

.section_why .cont_box_wrap .ll .box_in .desc {
    margin-bottom: 5.3rem;
    letter-spacing: -.05rem;
    max-width: 990px;
}

.section_why .cont_box_wrap .ll .box_in .desc.under_text {
    margin-bottom: 1.4rem;
}

.section_why .cont_box_wrap .manager_talk .btn_expert_video {
    display: block;
    position: absolute;
    right: 1rem;
    bottom: 0;
    padding: 6rem 16rem 3.2rem 5rem;
    text-align: right;
}

.section_why .cont_box_wrap .talk span,
.section_why .cont_box_wrap .talk strong {
    display: block;
}

.section_why .cont_box_wrap .talk span {
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: -.05rem;
}

.section_why .cont_box_wrap .talk strong {
    position: relative;
    padding-right: 3rem;
    font-size: 1.8rem;
    line-height: 1.67;
    font-weight: 400;
    letter-spacing: -.05rem;
}

.section_why .cont_box_wrap .talk strong:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    width: 2.4rem;
    height: 2rem;
    margin-top: -1rem;
    background: url(../images/icon_youtube_primary.png) no-repeat center center;
}

.section_why .cont_box_wrap .talk span.in_block {
    margin-bottom: 0;
    margin-right: .4rem;
}

.section_why .cont_box_wrap .ll .box_in {
    background: no-repeat center top;
    background-size: cover;
}

.section_why .cont_box_wrap .ll .manager_talk .btn_expert_video {
    /* right: 8rem; */
    text-align: right;
}

.section_why .cont_box_wrap .ll .manager_talk .trans {
    position:relative;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    line-height: 1.25;
    letter-spacing: -2px;
    z-index:0;
}
.section_why .cont_box_wrap .ll .manager_talk .trans .under_box{
    position:relative;
}
.section_why .cont_box_wrap .ll .manager_talk .trans .under_box::after{
    content:"";
    position:absolute;
    bottom:3px;
    left:0;
    right:-2px;
    height:6px;
    background:#1DD1D3;
    z-index:-1;
}

[lang="ko"]  .section_why .cont_box_wrap .ll .manager_talk .trans {
    margin-bottom: 3.1rem;
    line-height: 1.15;
}
[lang="ja"] .section_why .cont_box_wrap .ll .manager_talk .trans {
    margin-bottom: 3.1rem;
    line-height: 0.85;
}
[lang="zh-CN"] .section_why .cont_box_wrap .ll .manager_talk .trans {
    margin-bottom: 3.1rem;
    line-height: 1.15;
}
[lang="en"] .section_why .cont_box_wrap .ll .manager_talk .trans {
    margin-bottom: 3.1rem;
    line-height: 1.25;
}

/*.section_why .cont_box_wrap .ll .manager_talk .trans .ko,
.section_why .cont_box_wrap .ll .manager_talk .trans .num {
    display: inline-block;
    font-weight: 400;
    font-size: 1.8rem;
    vertical-align: text-bottom;
}*/

.section_why .cont_box_wrap .ll .manager_talk .trans .num {
    position: relative;
    padding-left: 45px;
    padding-right: .3rem;
    font-weight: 600;
}

.section_why .cont_box_wrap .ll .manager_talk .trans .num:before {
    content: "";
    display: block;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 12px;
    background: url(../images/arrow_both.png) no-repeat center center;
    background-size: contain;
}


.section_why .cont_box_wrap .ll .manager_talk .talk strong {
    padding-right: 3.4rem;
}

.section_why .cont_box_wrap .ll .manager_talk strong:after {
    right: .4rem;
}

.section_why .cont_box_wrap .ll .box_in:after {
    background-image: url(../images/ill_ll.png);
}

.section_why .cont_box_wrap .qa .box_in:after {
    background-image: url(../images/ill_qa.png);
}

.section_why .cont_box_wrap .ps .box_in:after {
    background-image: url(../images/ill_ps.png);
}

.section_why .cont_box_wrap .cm .box_in:after {
    background-image: url(../images/ill_cm.png);
}

.section_why .cont_box_wrap .gs .box_in:after {
    background-image: url(../images/ill_gs.png);
}

.section_why .cont_box_wrap .cp .box_in:after {
    background-image: url(../images/ill_cp.png);
}
/************************ section_why(E) ************************/

/************************ section_bottom(S) ************************/
.section_bottom {
    padding: 8.4rem 0 8.4rem;
    background: #743fa3;
}

[class*=section]+.section_bottom {
    margin-top: 12rem;
}

.section_bottom.contact {
    margin-top: 0rem;
}

.section_bottom .text_white {
    width: 80rem;
    font-size: 3rem;
    line-height: 1.667;
    font-weight: 400;
}

.section_bottom .btn_area {
    text-align: right;
}

.section_bottom .btn_basic {
    font-weight: 500;
    border: 0;
}

@media (hover: hover) {
    .section_bottom .btn_basic:hover {
        background: #1dd1d3;
        color: #743fa3;
    }
}
/************************ section_bottom(E) ************************/

/************************ section_discript(S) ************************/
.section_top+.section_discript {
    margin-top: 10.2rem;
    color: #743fa3;
}

.section_discript .title {
    display: block;
    margin-bottom: 3rem;
    font-size: 5rem;
    line-height: 1.28;
    letter-spacing: -.2rem;
    font-weight: 500;
    color: #743fa3;
}
[lang="ja"] .section_discript .title,
[lang="zh-CN"] .section_discript .title{
    font-weight: 600;
}

.section_discript .desc {
    font-size: 2.2rem;
    line-height: 1.6363;
    font-weight: 400;
    color: #444444
}
.section_discript .desc.purple {
    color: #743fa3;
}

.section_discript .dl_box {
    margin-top: 4rem;
}

.section_discript .dl_box dt {
    margin-bottom: 2.4rem;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 1.285;
}

.section_discript .dl_box dd {
    font-size: 2.2rem;
    line-height: 1.63;
    color: #444;
}

.section_discript .dl_box dl+dl {
    margin-top: 4rem;
}

.section_discript .step_box {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 9rem;
}

[lang="ja"] .section_discript .step_box {
    line-break: anywhere;
}

.section_discript .step_box dl {
    position: relative;
    flex: 1;
    text-align: center;
}

.section_discript .step_box dl+dl {
    padding-left: 5.6rem;
}

.section_discript .step_box.localization dl+dl {
    padding-left: 5.4rem;
}

.section_discript .step_box dl+dl:before {
    content: "";
    display: block;
    position: absolute;
    left: -13.3rem;
    top: 3.8rem;
    width: 32.2rem;
    height: .5rem;
    background: url(../images/step_dot.png) no-repeat center center;
}

.careers.section_discript .step_box dl+dl:before {
    content: "";
    display: block;
    position: absolute;
    left: -13.3rem;
    top: 3.8rem;
    width: 32.2rem;
    height: .5rem;
    background: none;
}

.section_discript .step_box dl dt {
    position: relative;
    margin-bottom: 2.8rem;
    font-size: 2.8rem;
    line-height: 1.285;
    font-weight: 700;
}

.section_discript .step_box dl dt .num {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 7.8rem;
    height: 7.8rem;
    margin: 0 auto 2.8rem;
    color: #fff;
    font-size: 0;
    text-indent: -9999px;
    background: #743fa3;
    box-shadow: .4rem .4rem 0 #1dd1d3;
}

.section_discript .step_box dl dt .num:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 3.6rem;
}

.section_discript .step_box dl:first-child dt .num:before {
    background-image: url(../images/icon_num_1.png);
}

.section_discript .step_box dl:nth-child(2) dt .num:before {
    background-image: url(../images/icon_num_2.png);
}

.section_discript .step_box dl:nth-child(3) dt .num:before {
    background-image: url(../images/icon_num_3.png);
}

.section_discript .step_box dl dd {
    font-size: 2rem;
    line-height: 1.5;
}
/************************ section_discript(E) ************************/

/************************ section_player(S) ************************/
.section_player+[class*=section_] {
    margin-top: 11rem;
}

.section_player .player {
    position: relative;
    width: 100%;
}

.section_player .player a {
    display: block;
    position: relative;
    height: 43rem;
}

.section_player .player .thumb {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #111;
    background-position: center center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    transition: all .3s ease;
}

.section_player .player a .thumb:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0;
    transition: opacity .3s ease;
    z-index: 2;
}

.section_player .player a:hover .thumb:before {
    opacity: .4;
}

.section_player .player .thumb:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8.8rem;
    height: 7.6rem;
    margin: -4.4rem 0 0 -3.8rem;
    background: url(../images/icon_youtube_play.png) no-repeat center center;
    z-index: 3;
}

.section_player .player iframe {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.section_player .text_box {
    padding: 0;
    background: #e7e6f5;
}

.section_player .text_box [class*=text_] {
    width: 100rem;
    height: 26.8rem;
    font-size: 3rem;
    line-height: 1.67;
    font-weight: 500;
}

.section_player .d_table {
    margin: 0 auto;
}

.section_player .btn_area {
    text-align: right;
}
/************************ section_player(E) ************************/

/************************ section_careers(S) ************************/
.careers_welfare_check {
    margin-top: -13rem;

}

.careers_welfare_check .text_box {
    padding: 0;
    background: #e7e6f5;
    height: 31rem;
}

.careers_welfare_check .text_box [class*=text_] {
    width: 100rem;
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 400;

}

.careers_welfare_check .d_table {
    margin: 0 auto;
    padding-top: 20rem;

}

.careers_welfare_check .btn_area {
    text-align: right;
    font-weight: 500;
}
/************************ section_careers(E) ************************/

/************************ list_items(S) ************************/
.list_items.qa {
    overflow: hidden;
}

.list_items.qa ul {
    display: flex;
    margin: -3.5rem -3.5rem 0;
}

.list_items.qa [class*=col] {
    display: flex;
    flex-direction: column;
    padding: 3.5rem 3.5rem 0;
}

.list_items.qa .item_inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #743fa3;
}

.list_items.qa [class*=col] .thumb {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0;
    padding-bottom: 47.35%;
}

.list_items.qa [class*=col] .thumb img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.list_items.qa [class*=col] .info {
    flex: none;
    height: 21.6rem;
    padding: 3.2rem 5.6rem 2rem;
}

.list_items.qa [class*=col] .info .tit {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-height: 3.8rem;
    margin-bottom: 1.4rem;
    font-size: 3.2rem;
    line-height: 1.185;
    color: #fff;
    font-weight: 500;
    text-overflow: ellipsis;
    word-break: break-word;
}

.list_items.qa [class*=col] .info .desc {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 7.8rem;
    text-overflow: ellipsis;
    color: rgba(255, 255, 255, 0.8);
    font-size: 2rem;
    line-height: 1.8;
}
/************************ list_items(E) ************************/

/************************ section_contents(S) ************************/
.section_contents .testing_list {
    display: flex;
    flex-wrap: nowrap;
}

.section_contents .testing_list ul {
    flex: 1;
}

.section_contents .dl_list > dl {
    margin-bottom: 2rem;
}

.section_contents .dl_list > dl > dt{
    margin-bottom: 2.4rem;
    font-weight: 700;
    font-size: 2.8rem;
    color:#743FA3;
}

.section_contents .dl_list > dl > dd{
    line-height: 160%;
    font-size: 2.2rem;
}
/************************ section_contents(E) ************************/

/************************ cont_contact(S) ************************/
#container.cont_contact {
    background-color: #f7f7f7;
}

.cont_contact .section_discript .title {
    margin-bottom: 1.2rem;
}

.cont_contact .section_contact {
    margin-top: 5.8rem;
}

.cont_contact .section_contact .inner {
    margin-bottom: -12rem;
    padding-bottom: 0rem;
}

.cont_contact .contactUs {
    color: #111;
    font-size: 2rem;
    line-height: 1.75;
}

.cont_contact .btn_area {
    margin-top: 7.2rem;
}



.cont_contact .btn_download {
    position: relative;
    width: 44rem;
    min-height: 6.2rem;
    padding: 0 3.8rem;
    font-weight: 700;
    color: #743fa3;
    font-size: 2.2rem;
    text-align: left;
    border: 4px solid #743fa3;
    letter-spacing: -.1rem;
}

.cont_contact .btn_download small {
    display: inline-block;
    margin-left: 1.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    vertical-align: .2rem;
}

.cont_contact .btn_download:after {
    content: "";
    display: block;
    position: absolute;
    right: 3.6rem;
    top: calc(50% - 1.2rem);
    width: 2.4rem;
    height: 2.4rem;
    background: url(../images/ico-download.png) no-repeat center center;
}

.section_content_wrap{
    display:flex;
    justify-content:space-between;
    gap:58px;
    margin:auto;
    width:127.8rem;
    max-width:100%;
}
.content_form{
    margin-top: 0px;
    width: 479px;
    height: 678px;
}

.form_contact > .form_wrap{
    min-height: 678px;
    margin-top: 10px;
    padding: 5.6rem 5.6rem;
    box-shadow: .4rem .4rem #1dd1d3;
    background: #fff;
}

.form_wrap > .form_group{
    width: 383px;
    margin: 4rem -.4rem;
}
/************************ cont_contact(E) ************************/

/************************ error_page(S) ************************/
.error_page {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
}

.error_page .logo {
    width: 20rem;
    height: 9.1rem;
    margin-bottom: 6.8rem;
    font-size: 0;
    text-indent: -9999px;
    background: url(../images/bi-purple_200x91.png) no-repeat center center;
}

.error_page .text_primary {
    display: block;
    margin-bottom: 3rem;
    font-size: 5.6rem;
    line-height: 7.8rem;
    font-weight: 400;
    letter-spacing: -.1rem;
    text-align: center;
}

.error_page .cont {
    margin-bottom: 6.8rem;
    font-size: 2.2rem;
    line-height: 3.6rem;
    text-align: center;
}

.error_page .btn_basic {
    width: 38.8rem;
    font-size: 2.2rem;
}
/************************ error_page(E) ************************/


/************************ loading(S) ************************/
.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.loading .loading_icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 5rem;
    height: 5rem;
    margin: -2.5rem 0 0 -2.5rem;
    background: url(../images/loading_off.svg) no-repeat center center;
    background-size: 100%;
}

.loading .loading_icon_on {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 5rem;
    height: 5rem;
    margin: -2.5rem 0 0 -2.5rem;
    transform: rotate(45deg);
}

.loading .loading_icon_on:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background: url(../images/loading_on.svg) no-repeat left top;
    background-size: 5rem 5rem;
    transform-origin: center;
    animation: loadingbi 1.7s linear infinite;
}


@keyframes loadingbi {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
/************************ loading(E) ************************/

/************************ Game Sound/Voice-Over(S) ************************/
.bg_img_content{
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding-top: 150px;
}
.bg_img_content > .title{
    margin-bottom: 42px;
    line-height: 50px;
    font-size: 3.6rem;
    font-weight: 700;
}
.bg_img_content > div{
    height:320px;
    background-repeat: no-repeat;
    background-size: auto 100%;
}
.bg_img_content > div.type1{
    background-image: url(../images/bg_img_content_type1.svg);
}
.bg_img_content > div.type2{
    background-image: url(../images/bg_img_content_type2.svg);
}
.bg_img_content > div.type3{
    background-image: url(../images/bg_img_content_type3.svg);
}
.bg_img_content > div.type4{
    background-image: url(../images/bg_img_content_type4.svg);
}
.bg_img_content > div.type5{
    background-image: url(../images/bg_img_content_type5.svg);
}
.bg_img_content > div.type6{
    background-image: url(../images/bg_img_content_type6.svg);
}
.bg_img_content .left_content{
    padding-right: 446px;
    background-position: right center;
}
.bg_img_content .right_content{
    padding-left: 446px;
    background-position: left center;
}
.bg_img_content > div > .title{
    margin-bottom: 16px;
    line-height: 50px;
    font-size: 3.6rem;
    font-weight: 700;
    letter-spacing: -1px;
}
.bg_img_content > div > p{
    line-height: 30px;
    font-size: 1.7rem;
    font-weight: 400;
    letter-spacing: -1px;
    color: #444; 
}
.process_wrap > .title{
    display: block;
    margin-bottom: 40px;
    line-height: 50px;
    font-size: 3.6rem;
    font-weight: 700;
    color:#743FA3;
}
.process_wrap .process_box{
    display: flex;
    flex-direction: column;
    gap: 42px;
}
.process_wrap .title_box{
    display: flex;
    gap:12px;
    padding-left: 156px;
}
.process_wrap .title_box strong{
    position: relative;
    flex:1;
    height:56px;
    line-height: 56px;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: -1px;
    color:#fff;
    background-color:#743FA3;
}
.process_wrap .title_box strong:before{
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -37px;
    width: 5px;
    height: 32px;
    background: url(../images/icon_round.svg) no-repeat center center;
}
.process_wrap .content_box{
    display: grid;
    grid-template-columns: 144px 1fr 1fr;
    column-gap: 12px;
}
.process_wrap .content_box .col_title{
    display: grid;
    justify-content: center;
    align-items: center;
    line-height: 23px;
    font-size:1.7rem;
    font-weight: 700;
    text-align: center;
    color:#743FA3;
    background-color: #E7E6F5;
}
.process_wrap .content_box .item_box{
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 36px 20px;
    text-align: center;
    background-color: #F5F5FB;
}
.process_wrap .content_box:not(:last-child) .item_box:before{
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: -37px;
    width: 5px;
    height: 32px;
    background: url(../images/icon_round.svg) no-repeat center center;
}
.process_wrap .item_box .list_title{
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 26px;
    color: #444;
}
.process_wrap .item_box ul li{
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 150%;
    color: #444;
}
:lang(ja) .process_wrap .item_box .list_title,
:lang(ja) .process_wrap .item_box ul li{
    word-break: break-all;
}
/************************ Game Sound/Voice-Over(E) ************************/

.white_space_nowrap {
    white-space: nowrap;
}

.careers_ico_1,
.careers_ico_2,
.careers_ico_3 {
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 10rem;
    margin-bottom: 3rem;
}

.careers_ico_1 {
    background-image: url(../images/careers_ico_1.png);
}

.careers_ico_2 {
    background-image: url(../images/careers_ico_2.png);
}

.careers_ico_3 {
    background-image: url(../images/careers_ico_3.png);
}

.careers_right_arrow {
    background-image: url(../images/careers_right_arrow.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 16px;
}

.pentapeakstudios_logo {
    background-image: url(../images/pentapeakstudios_logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 60px;
}

.talent_pool_division {
    background-image: url(../images/talent_pool_division.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 236px;
}

.talent_pool_job {
    background-image: url(../images/talent_pool_job.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 236px;
}

/********************* 다국어 영역 (S) *********************/
:lang(ja) .section_progress .toggle_box .cont {
    /* width: auto */
}

:lang(ja) .section_bottom .text_white {
    font-size: 2rem;
    line-height: 1.4;
    word-break: break-all;
}

:lang(ja) .section_player .text_box [class*=text_] {
    padding: 4rem 0;
    word-break: break-word;
}

:lang(ja) .section_progress .toggle_box .box_tit strong {
    padding: 0 1.4rem;
    font-size: 1.5rem;
}

/* 다국어 Home */
:lang(zh-CN) .section_discript .step_box dl dd,
:lang(ja) .section_discript .step_box dl dd {
    word-break: break-all;
}

:lang(zh-CN) .progress_box .flex_box .prog_body .prog_con .con,
:lang(ja) .progress_box .flex_box .prog_body .prog_con .con {
    word-break: break-all;
}

/* 다국어 ForStages 페이지에서 bg_step2 이미지*/
:lang(zh-CN) .section_progress .step_list_wrap li.step2 {
    padding-bottom: 8.5rem;
    background: url(../images/bg_step2_zh-CN.png) no-repeat left 5rem top 6.1rem;
}

:lang(ja) .section_progress .step_list_wrap li.step2 {
    padding-bottom: 8.5rem;
    background: url(../images/bg_step2_ja.png) no-repeat left 5rem top 6.1rem;
}

:lang(ja) .en {
    font-size: 1.3rem;
}

:lang(ja) .dropdown_items .en {
    font-size: 1.6rem;
}

.notice_wrap {
    display: none;
}

:lang(ko) .notice_wrap {
    display: flex;
}
/********************* 다국어 영역 (E) *********************/

/********************* 기타 공통 클래스 영역 (S) *********************/
.boder-0 {
    border: 0 !important;
}

.boder-bottom-1 {
    border-bottom: #E1E1E1 solid 1px;
}
.cont_pv .section {
    margin-top: 8rem;
    margin-bottom: 14.2rem;
}

.cont_pv .section .title-box h2 {
    font-size: 3.6rem;
    color: #743FA3;
    margin-bottom: 3.3rem;
}

.cont_pv .section .title-box h2>span {
    font-size: 2.2rem;
    color: #828282;
    font-weight: 400;
}

.cont_pv .section .title-box p {
    font-size: 2.2rem;
    line-height: 1.63;
    word-break: keep-all;
}

.cont_pv .section .p2 {
    font-size: 1.8rem;
}

.cont_pv .section .dl_box {
    margin-top: 4rem;
    border-top: 1px solid #E1E1E1;
}

.cont_pv .section .dl_box dl {
    padding: 4.5rem 3.4rem;
    border-top: 1px solid #E1E1E1;
}

.cont_pv .section .dl_box dt {
    margin-bottom: 2.4rem;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 1.285;
    color: #743FA3;
}

.cont_pv .section .dl_box dd {
    font-size: 1.8rem;
    line-height: 1.66;
    margin-bottom: 1.8rem;
}

.cont_pv .section .dl_box dd.mb5 {
    margin-bottom: 0.5rem;
}

.cont_pv .section .dl_box dd strong {
    font-size: 1.8rem;
    font-size: 2rem;
    font-weight: 700;
}

.cont_pv .section .dl_box dd strong.point {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 3.6rem;
}

.cont_pv .section .dl_box dd span {
    display: block;
    font-size: 1.8rem;
    color: #828282;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.cont_pv .section .dl_box dd span.flex {
    display: flex;
    align-items: center;

}

.cont_pv .section .dl_box dd span.fw500 {
    font-weight: 500;
}

.cont_pv .section .dl_box dd span div.point_area {
    width: 3rem;
    height: 3rem;
    color: #444444;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cont_pv .section .dl_box dd div.point {

    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #828282;
}

.cont_pv .section .dl_box dd em.point {

    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #828282;
}

.cont_pv .section .dl_box dd div.flex {
    display: flex;
}

.not-indent {
    margin-left: -15px !important;
    display: inline !important;
    color: #111 !important;
    padding-right: 3px;
}

.cont_nt .section {
    margin-top: 8rem;
    margin-bottom: 10.1rem;
}

.cont_nt .section .title-box h2 {
    font-size: 3.6rem;
    color: #743FA3;
    margin-bottom: 3.3rem;
}

.cont_nt .section .title-box h2>span {
    font-size: 2.2rem;
    color: #828282;
    font-weight: 400;
}

.cont_nt .section .title-box p {
    font-size: 2.2rem;
    line-height: 1.63;
    word-break: keep-all;
}

.cont_nt .section .p2 {
    font-size: 1.8rem;
}

.cont_nt .section .dl_box dl {
    margin-bottom: 2.4rem;
}

.cont_nt .section .dl_box dt {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.66;
    color: #111;
}

.cont_nt .section .dl_box dd {
    margin-left: 15px;
    font-size: 1.8rem;
    line-height: 1.66;
    color: #111;
    word-break: keep-all;
}

.cont_nt .section .dl_box dd dl {
    margin-bottom: 0;
}

.cont_nt .section .dl_box dd dl dt {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.66;
    color: #111;
}

.cont_nt .section .dl_box dd dl dd {
    margin-left: 18px;
    font-size: 1.8rem;
    line-height: 1.66;
    color: #828282;
    word-break: keep-all;
}

.cont_nt .section .dl_box dd span {
    display: block;
    font-size: 1.8rem;
    color: #828282;
    font-weight: 400;
}

.cont_nt .footer-text {
    text-align: right;
    font-size: 1.6rem;
    line-height: 1.63;
    color: #111;
}

.privacyBtn {
    font-size: 1.4rem;
    line-height: 4rem;
    width: max-content;
}

.privacyTextBtn {
    text-decoration: underline !important;
    font-weight: 500;
}

:lang(zh-CN) .privacyTextBtn {
    word-break: break-all;
}


.notice_wrap {
    width: 100%;
    background-color: #743FA3;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.notice_wrap .notice_text a {
    padding: 1.6rem;
    font-size: 2.2rem;
    font-weight: 700;
    color: #FFF;
}

.arrow-icon {
    width: 22.5px;
    height: 18px;
    margin-top: 3px;
    margin-left: 7px;
}

.PrivacyChangeButtonPositionRef {
    position: relative;
    margin-bottom: 80px;
}

.PrivacyChangeButton {
    position: absolute;
    bottom: -2.2rem;
    right: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* min-width: 40rem; */
    height: 5rem;
    text-align: left;
    font-size: 1.8rem;
    line-height: 3rem;
    padding: 1rem 2rem;
    color: #743fa3;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
}

.PrivacyChangeButton>svg {
    margin-left: 8rem;
}

.careers.section_blog {
    padding-top: 8rem;
}

/* .section_blog .col3 {
    float: left;
    padding: 1rem 2.85rem;
} */
.boxIcon {
    width: 30px;
    height: 30px;
    padding: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    display: none;

}

.boxIcon .box {
    width: 7.1px;
    height: 7.1px;
    background: #743FA3;
}

.careers .open {
    max-height: 5000px;
}

.cont_cm .section_progress .step_list_wrap li.step1 {
    padding-bottom: 8.2rem;
    background: url(../images/bg_cm_step1.png) no-repeat right 15rem top 3.6rem;
}

.cont_cm .section_progress .step_list_wrap li.step2 {
    padding-bottom: 8rem;
    background: url(../images/bg_cm_step2.png) no-repeat left 15rem top 4rem;
}

.cont_cm .section_progress .step_list_wrap li.step3 {
    padding-bottom: 1.2rem;
    background: url(../images/bg_cm_step3.png) no-repeat right 15rem top 0rem;
}

.cont_cm .section_progress .step_list_wrap li.step4 {
    padding-bottom: 2rem;
    background: url(../images/bg_cm_step4.png) no-repeat left 11rem top 6rem;
}


.cont_cm .section_progress .step_list_wrap li.step5 {
    padding-bottom: 2.3rem;
    background: url(../images/bg_cm_step5.png) no-repeat right 7.6rem top 2rem;
}

.cont_cm .section_progress .step_cont {
    min-height: 30rem;
}

.cont_cm .section_progress .step_list_wrap li.step6 {
    padding-bottom: 6.3rem;
    background: url(../images/bg_cm_step6.png) no-repeat left 15rem bottom 13rem;
}

:lang(zh-CN) .cont_cm .section_progress .step_list_wrap li.step6{
    padding-bottom : 9.3rem;
}

.cont_cm .section_progress .step_list_wrap li.right.last .bottom_line {
    top: 38.5rem;
    left: 31.7rem
}

.cont_cm .section_progress .step_list_wrap li.step1 .step_cont {
    padding-bottom: 5.8rem;
}

.cont_cm .section_progress .step_list_wrap li.step2 .toggle_box {
    padding-bottom: 2.5rem;
}

.cont_cm .section_progress .step_list_wrap li.step3 .toggle_box {
    padding-bottom: 5.9rem;
}

.cont_cm .section_progress .step_list_wrap li.step4 .toggle_box {
    padding-bottom: 5.4rem;
}

/********************* 기타 공통 클래스 영역 (E) *********************/

/************************ 반응형 1320px(S) ************************/
@media screen and (max-width: 1320px) {
    /* section_top(S) */
    .section_top .tab_wrap:before,
    .section_top .tab_wrap:after {
        left: 2.4rem;
    }

    .section_top .tab_box {
        max-width: 100%;
    }

    .section_top .tab_list {
        width: calc(100% - 2.4rem);
        margin: 0;
    }
    :lang(en) .section_top .tab_list .text{
        font-size: 1.5rem;
    }
    /* section_top(E) */

    /* progress(S) */
    .progress_box .flex_box {
        padding: 0;
    }

    .progress_box .flex_box>li+li {
        margin-left: 2.4rem;
    }

    .progress_box .flex_box>li+li:before {
        left: -2rem;
        top: 50%;
        width: 1.7rem;
    }

    .progress_box.sm .flex_box {
        padding: 0;
    }

    .progress_box.sm .flex_box>li+li {
        margin-left: 2.4rem;
    }

    .progress_box.sm .flex_box>li+li:before {
        left: -2rem;
        width: 1.7rem;
    }
    /* progress(E) */

    /* section_why(S) */
    .main [class*=section]+.section_why {
        margin-top: 6rem;
    }

    .section_why .h_title_box {
        margin-bottom: 0rem;
    }

    .section_why .cont_box_wrap {
        margin: 0;
    }

    .section_why .cont_box_wrap .box[class*=col] {
        padding: 0;
    }
    .section_why .cont_box_wrap .box_in::after{
        display: none;
    }

    .section_why .cont_box_wrap .box_in .heading {
        left: 4rem;
    }

    .section_why .cont_box_wrap .box_in .desc {
        margin-bottom: 3.2rem;
    }

    .section_why .cont_box_wrap .ll .box_in .desc {
        margin-bottom: 7.3rem;
    }

    .section_why .cont_box_wrap .ll .manager_talk .btn_expert_video {
        right: 1.8rem;
    }   
    /* section_why(E) */

    /* section_bottom(S) */
    .section_bottom {
        padding: 5.6rem 0 5.6rem;
    }

    .section_bottom .text_white {
        display: block;
        width: 100%;
        text-align: center;
    }

    .section_bottom .btn_area {
        display: block;
        width: 100%;
        margin-top: 4rem;
        text-align: center;
    }
    /* section_bottom(E) */

    /* section_discript(S) */
    .section_discript .step_box dl+dl {
        padding-left: 2.4rem;
    }

    .section_discript .step_box dl+dl:before {
        left: calc((-50% + 2.85rem + 2.4rem));
        top: 2.7rem;
        width: calc(100% - 5.7rem - 2.4rem);
        background: url(../images/step_dot.png) no-repeat center center;
    }

    .section_discript .step_box dl dt {
        margin-bottom: 2.4rem;
    }

    .section_discript .step_box dl dt .num {
        width: 5.7rem;
        height: 5.7rem;
        margin: 0 auto 2.7rem;
        line-height: 6rem;
        box-shadow: .3rem .3rem 0 #1dd1d3;
    }

    .section_discript .step_box dl dt .num:before {
        background-size: auto 2.7rem;
    }

    .section_discript .step_box dl:first-child dt .num:before {
        background-image: url(../images/icon_num_1_md.png);
    }

    .section_discript .step_box dl:nth-child(2) dt .num:before {
        background-image: url(../images/icon_num_2_md.png);
    }

    .section_discript .step_box dl:nth-child(3) dt .num:before {
        background-image: url(../images/icon_num_3_md.png);
    }
    /* section_discript(E) */

    /* section_progress(S) */
    .section_progress .step_list_wrap .right {
        padding-left: 40.04%;
    }

    .section_progress .step_list_wrap li.left[class*=step]:before {
        left: 3.8rem;
    }

    .section_progress .step_list_wrap li.right[class*=step]:before {
        left: calc(40.04% + 3.8rem);
    }

    .section_progress .step_list_wrap li[class*=step] .bottom_line {
        width: calc(40.04% + .5rem);
    }

    .section_progress .step_list_wrap li.right.last .bottom_line {
        margin-left: calc(-33rem + .5rem);
    }


    .section_progress .step_list_wrap li.left.step1:before {
        height: auto;
    }

    .section_progress .step_list_wrap li.right.step2:before {
        height: auto;
    }

    .section_progress .step_list_wrap li.right.step3:before {
        height: auto;
    }

    .section_progress .step_list_wrap li.right.step4:before {
        height: auto;
    }

    .section_progress .step_list_wrap li.step1 {
        background: url(../images/bg_step1_md.png) no-repeat right 0 top 2.2rem / 290px auto;
    }
    :lang(en) .section_progress .step_list_wrap li.step1{
        background-position: right 0 top 7.2rem;
    }

    .section_progress .step_list_wrap li.step2 {
        padding-bottom: 8.5rem;
        background: url(../images/bg_step2_md.png) no-repeat left 0 top 14.2rem / 290px auto;
    }
    :lang(ja) .section_progress .step_list_wrap li.step2{
        background-size: 330px auto;

    }

    .section_progress .step_list_wrap li.step3 {
        background: url(../images/bg_step3_md.png) no-repeat right 0 top 18.8rem;
    }
    :lang(en) .section_progress .step_list_wrap li.step3{
        background-size: 330px auto;
        background-position: right 0 top 24.8rem;
    }

    .section_progress .step_list_wrap li.step4 {
        padding-bottom: 3.3rem;
        background: url(../images/bg_step4_md.png) no-repeat left 0 bottom 27.7rem / 300px;
    }
    :lang(ja) .section_progress .step_list_wrap li.step4{
        padding-bottom: 0;
    }

    .section_progress .step_list_wrap li.step1 .step_cont {
        padding-bottom: 5.8rem;
    }

    .section_progress .toggle_box .cont {
        max-width: 85%;
    }

    .section_progress .step_list_wrap li.step2 .toggle_box {
        padding-bottom: 2.5rem;
    }

    .section_progress .step_list_wrap li.step3 .toggle_box {
        padding-bottom: 5.9rem;
    }

    .section_progress .step_list_wrap li.step4 .toggle_box {
        padding-bottom: 5.4rem;
    }
    /* section_progress(E) */

    /* section_player(S) */ 
    .section_player .text_box .d_table {
        display: block;
        padding: 5.6rem 2.4rem;
    }

    .section_player .text_box .d_table .d_cell {
        display: block;
        text-align: center;
    }

    .section_player .text_box [class*=text_] {
        width: 100% !important;
        height: auto;
        font-size: 3rem;
        line-height: 1.67;
        font-weight: 500;
    }

    .section_player .d_table {
        margin: 0 auto;
    }

    .section_player .d_table .btn_area {
        margin-top: 4rem;
    }
    /* section_player(E) */

    /* ps_table(S) */
    .ps_table.flex_box {
        flex-wrap: wrap;
    }

    .ps_table.flex_box .item {
        width: 100%;
    }

    .ps_table.flex_box .item+.item {
        margin-top: 11.8rem;
    }

    .ps_table.flex_box .img img {
        width: 100%;
        max-width: 520px;
    }
    /* ps_table(E) */

    /* list_items(S) */
    .list_items.qa ul {
        margin: -2.4rem -1.2rem 0;
    }

    .list_items.qa [class*=col] {
        padding: 2.4rem 1.2rem 0;
    }

    .list_items.qa [class*=col] .thumb {
        padding-bottom: 70.44%;
    }
    /* list_items(E) */

    /* contact(S) */
    .section_content_wrap{
        flex-direction: column;
        gap:0;
        margin-bottom: 65px;
        padding:0 25px;
    }
    .section_content_box{
        margin-bottom: 186px;
    }
    .section_contact .contactUs{
        position: relative;
    }
    .section_contact .contactUs > img{
        position: absolute;
        bottom:-186px;
        right:-20px;
    }
    .content_form{
        width:100%;
        height: auto;
    }
    .form_contact > .form_wrap{
        margin-top:0;
        padding: 5.6rem 4.8rem;
        height: auto;
    }
    .cont_contact .section_contact .inner {
        background-position: right bottom;
    }

    .careers_welfare_check {
        margin-top: -17rem;
    }

    .careers_welfare_check .text_box {
        height: 40rem;
    }

    .careers_welfare_check .text_box .d_table {
        display: block;
        padding-top: 18rem;
    }

    .careers_welfare_check .text_box .d_table .d_cell {
        display: block;
        text-align: center;
    }

    .careers_welfare_check .text_box [class*=text_] {
        width: 100%;
        height: auto;
        font-size: 2.4rem;
        line-height: 3.6rem;
        font-weight: 400;
    }

    .careers_welfare_check .d_table {
        margin: 0 auto;
    }

    .careers_welfare_check .d_table .btn_area {
        margin-top: 4rem;

    }
    /* contact(E) */

    /* services_box(S) */
    .services_box{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 24px;
        column-gap: 16px;
        padding-top: 32px;
    }
    .services_box .box{
        width: auto;
    }
    .services_box .box > img{
        margin-top: auto;
    }
    .services_box .box > button{
        margin-top: auto;
        width: 100%;
    }
    /* services_box(E) */

    /* 다국어 영역 (S) */
    :lang(zh-CN) .section_progress .step_list_wrap li.step2,
    :lang(ja) .section_progress .step_list_wrap li.step2 {
        background-position: left 0 top 14.2rem;
        background-repeat: no-repeat;
        background-size: 320px auto;
    }

    :lang(zh-CN) .section_progress .step_list_wrap li.step2 {
        background-image: url(../images/bg_step2_zh-CN.png);
    }

    /* :lang(ja) .section_progress .step_list_wrap li.step2 {
        background-image: url(../images/bg_step2_ja.png);
    } */

    :lang(ja) .section_player .text_box [class*=text_] {
        padding: 0;
        word-break: break-word;
    }

    /* 다국어 영역 (E) */

    /* 기타 공통 영역(S) */
    .section_progress .step_list_wrap li.step1 .step_cont{
        padding-right: 33rem;
    }
    .cont_cm .section_progress .step_list_wrap li.left.step1:before {
        height: auto;
    }

    .cont_cm .section_progress .step_list_wrap li.right.step2:before {
        height: auto;
    }

    .cont_cm .section_progress .step_list_wrap li.right.step3:before {
        height: auto;
    }

    .cont_cm .section_progress .step_list_wrap li.right.step4:before {
        height: auto;
    }

    .cont_cm .section_progress .step_list_wrap li.step1 {
        background: url(../images/bg_cm_step1.png) no-repeat right 0 top 8rem / 320px auto;
    }
    .cont_cm .section_progress .step_list_wrap li.step2 {
        padding-bottom: 8.5rem;
        background: url(../images/bg_cm_step2.png) no-repeat left 0 top 8rem / 320px auto;
    }

    .cont_cm .section_progress .step_list_wrap li.step3 {
        background: url(../images/bg_cm_step3.png) no-repeat right 0 top 8rem / 320px auto;
    }

    .cont_cm .section_progress .step_list_wrap li.step4 {
        background: url(../images/bg_cm_step4.png) no-repeat left 0 top 8rem / 320px auto;
    }
    .cont_cm .section_progress .step_list_wrap li.step5 {
        background: url(../images/bg_cm_step5.png) no-repeat right 0 top 4rem / 330px auto;
    }
    .cont_cm .section_progress .step_list_wrap li.step6 {
        padding-bottom:0;
        background: url(../images/bg_cm_step6.png) no-repeat left 0 top 4rem / 300px auto;
    }

    :lang(en) .cont_cm .section_progress .step_list_wrap li.step1 {
        background-size:300px auto;
    }
    :lang(en) .cont_cm .section_progress .step_list_wrap li.step2 {
        background-size:330px auto;
    }
    :lang(en) .cont_cm .section_progress .step_list_wrap li.step3 {
        background-size:280px auto;
    }
    :lang(en) .cont_cm .section_progress .step_list_wrap li.step4 {
        background-size:330px auto;
    }
    :lang(en) .cont_cm .section_progress .step_list_wrap li.step5 {
        background-size:290px auto;
    }
    :lang(en) .cont_cm .section_progress .step_list_wrap li.step6 {
        background-size:330px auto;
    }

    :lang(zh-CN) .cont_cm .section_progress .step_list_wrap li.step6{
        padding-bottom: 11.3rem;
    }

    .cont_cm .section_progress .step_list_wrap li.right.last .bottom_line {
        margin-left: -22.3rem;
    }

    .cont_cm .section_progress .step_list_wrap li.step1 .step_cont {
        padding-bottom: 5.8rem;
        padding-right: 33rem;
    }

    .cont_cm .section_progress .toggle_box .cont {
        max-width: 85%;
    }

    .cont_cm .section_progress .step_list_wrap li.step2 .toggle_box {
        padding-bottom: 2.5rem;
    }

    .cont_cm .section_progress .step_list_wrap li.step3 .toggle_box {
        padding-bottom: 5.9rem;
    }
    .cont_cm .section_progress .step_list_wrap li.step3 .step_cont {
        padding-right: 33rem;
    }
    :lang(en) .cont_cm .section_progress .step_list_wrap li.step3 .step_cont{
        padding-right:28rem;
        min-height: 33rem;
    }
    
    .cont_cm .section_progress .step_list_wrap li.step4 .toggle_box {
        padding-bottom: 5.4rem;
    }

    .cont_cm .section_progress .step_list_wrap li.step5 .toggle_box {
        padding-bottom: 5.4rem;
    }
    .cont_cm .section_progress .step_list_wrap li.step5 .step_cont {
        padding-right: 33rem;
    }

    .cont_cm .section_progress .step_list_wrap li.step6 .toggle_box {
        padding-bottom: 5.4rem;
    }

    .cont_cm .section_progress .step_list_wrap li.right.last .bottom_line {
        top: 28rem;
        left: calc(40.04% + -6.3rem);
    }
    .cont_portfolio .section_portfolio{
        margin-top:10rem;
    }
    .portfolio_list_wrap .list_open_type{
        margin-top:0;
    }
    .m_portfolio_list_items.list_open_type > ul{
        justify-content: center;
    }
    /* 기타 공통 영역(E) */
}
/************************ 반응형 1320px(E) ************************/

/************************ 반응형 992px(S) ************************/
@media screen and (max-width: 992px) {

    .floating_btn_wrap {
        transition: opacity .3s ease, visibility .3s ease;
    }

    .floating_btn_wrap.show {
        opacity: 1;
        visibility: visible;
    }

    .careers .page_cover.life {
        background-image: url(../images/tab_img_05_on.png);
        background-size: cover;
        background-position: right;
    }

    .careers .page_cover.jobs {
        background-image: url(../images/tab_img_06_on.png);
        background-size: cover;
    }

    /* section_top(S) */
    .section_top {
        padding-top: 5.6rem;
        background: #743fa3;
    }
    .section_top .page_cover {
        position: relative;
        height: auto;
        padding-top: 71.11%;
        color: #fff;
        background-color: #743fa3;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .section_top .page_cover .cont_title {
        text-align: center;
        white-space: normal;
    }
    .section_top.section_top_why2bytes .page_cover {
        background-image: url(../images/img_why2bytes_sm.png);
        background-position: 13% top;
    }
    .section_top.section_top_services .page_cover {
        background-image: url(../images/section_top_ll.png);
    }
    .cont_gv .section_top .page_cover {
        background-position: bottom;
    }

    .section_top .page_cover:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(360deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0));
        z-index: 1;
    }

    .section_top .page_cover .inner {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 2.8rem 2rem;
        z-index: 2;
    }

    .section_top .page_cover .cont_title {
        margin-bottom: .8rem;
        font-size: 5.6rem;
        line-height: 1.3;
        letter-spacing: -.1rem;
    }

    .section_top .page_cover .desc {
        text-align: center;
        font-size: 2.4rem;
        line-height: 1.6;
        white-space: normal;
    }

    .section_top .page_cover .desc strong {
        font-weight: 500;
        word-break: break-word;
    }

    .cont_ll .section_top .page_cover {
        background-size: auto 100%;
    }

    .cont_qa .section_top .page_cover {
        background-image: url(../images/tab_img_04_on.png);
    }

    .cont_ps .section_top .page_cover {
        background-image: url(../images/tab_img_02_on.png);
    }

    .cont_cm .section_top .page_cover {
        background-image: url(../images/section_top_cm.png);
    }
    .cont_gv .section_top .page_cover {
        background-image: url(../images/tab_img_07_on.png);
    }
    .cont_cp .section_top .page_cover {
        background-image: url(../images/section_top_cp.png);
    }

    .cont_contact .section_top .page_cover {
        background-image: url(../images/img_contact_tab_sm.png);
    }

    .cont_portpolio .section_top .text_tab .tab_list {
        background-image: none;
    }

    .cont_portpolio .section_top .page_cover {
        background-image: url(../images/img_portfolio_tab_sm.jpg);
    }

    .section_top .tab_wrap {
        position: fixed;
        top: 6.4rem;
        left: 0;
        right: 0;
        height: 5.6rem;
        background: #743fa3;
        z-index: 98;
    }

    :lang(ko) .section_top .tab_wrap {
        position: fixed;
        top: 12.4rem;
        left: 0;
        right: 0;
        height: 5.6rem;
        background: #743fa3;
        z-index: 98;
    }

    .section_top .tab_wrap.fixed_top {
        position: fixed;
        top: 0;
    }

    .section_top .tab_wrap:before,
    .section_top .tab_wrap:after {
        display: none;
    }

    .section_top .tab_box {
        justify-content: flex-start;
        max-width: 100%;
        margin: 0;
        padding: 0 2rem;
    }

    .section_top .tab_list {
        justify-content: space-evenly;
        width: 100%;
    }

    .section_top .tab_list li {
        width: auto;
    }

    .section_top .tab_list li.col1 {
        width: 100% !important;
    }

    .section_top .tab_list li span {
        background: transparent;
    }

    .section_top .tab_list li span .thumb {
        display: none;
    }

    .section_top .tab_list .text {
        position: relative;
        height: 5.6rem;
        padding: 0 .8rem;
        color: #fff;
        font-size: 1.5rem;
        line-height: 5.6rem;
        opacity: .6;
        background: transparent;
        white-space: nowrap;
    }

    .section_top .tab_list .text:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: .4rem;
        transform: translateX(-50%);
        background: #1dd1d3;
        transition: width .3s ease;
    }

    .section_top .tab_list span:hover .text,
    .section_top .tab_list .on span .text {
        height: 5.6rem;
        color: #fff;
        opacity: 1;
        background: transparent;
    }

    .section_top .tab_list .on span .text:after,
    .section_top .tab_list span:hover .text:after {
        width: 100%;
    }

    .section_top .text_tab .tab_list {
        padding-top: 0;
    }

    .section_top .tab_list li a {
        background: transparent;
    }

    .section_top .tab_list li a .thumb {
        display: none;
    }

    .section_top .tab_list a:hover .text,
    .section_top .tab_list .on a .text {
        height: 5.6rem;
        color: #fff;
        opacity: 1;
        background: transparent;
    }
    .careers.section_top .tab_list a:hover .text, 
    .careers.section_top .tab_list .on a .text{
        height: 5.6rem;
    }

    .section_top .tab_list .on a .text:after,
    .section_top .tab_list a:hover .text:after {
        width: 100%;
    }
    .section_top_services .tab_box{
        display: none;
    }
    .section_top_services .tab_box_mo{
        display: block;
        position: relative;
        height:100%;
    }
    .section_top_services .tab_box_mo .title{
        display: flex;
        align-items: center;
        padding: 0 55px 0 20px;
        width: 100%;
        height: 100%;
        font-size: 1.6rem;
        letter-spacing: -0.05rem;
        color: #fff;
        background: url(../images/ico_arrow_off.svg) no-repeat center right 20px;
    }
    .section_top_services .tab_box_mo .title.on{
        background-image: url(../images/ico_arrow_on.svg);
    }
    .section_top_services .tab_box_mo .tab_list_mo{
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        background-color: #fff;
        border: 1px solid #D5D3E4;
    }
    .section_top_services .tab_box_mo .tab_list_mo > li > a{
        display: block;
        padding: 10px 20px;
        line-height: 30px;
        font-size: 1.8rem;
        color: #444;
    }
    .section_top_services .tab_box_mo .tab_list_mo > li.on > a{
        color:#743FA3;
    }
    /* section_top(E) */

    /* table_type(S) */
    .table_type thead th {
        font-size: 1.4rem;
        line-height: 1.6;
    }

    .table_type tbody td {
        font-size: 1.4rem;
        line-height: 1.6;
    }
    /* table_type(E) */

    /* progress_box(S) */
    .progress_box {
        overflow: hidden;
    }

    .progress_box .flex_box {
        flex-wrap: wrap;
        margin: -1.6rem -.8rem 0;
    }

    .progress_box .flex_box>li {
        flex: none;
        width: 50%;
        padding: 1.6rem .8rem 0;
    }

    .progress_box .flex_box>li+li {
        margin-left: 0;
    }

    .progress_box .flex_box>li+li:before {
        display: none;
    }

    .progress_box .flex_box .prog_head {
        height: 4rem;
        font-size: 1.4rem !important;
        line-height: 1.6;
        font-weight: 500;
    }

    .progress_box .flex_box .prog_body {
        padding: 2.4rem 1.6rem;
    }

    .progress_box .flex_box .prog_body .prog_con+.prog_con {
        margin-top: 1.6rem;
    }

    .progress_box .flex_box .prog_body .prog_con .tit,
    .progress_box .flex_box .prog_body .prog_con .con {
        font-size: 1.4rem;
        line-height: 1.6;
    }

    .progress_box.sm .flex_box>li {
        width: 50%;
        padding: 1.6rem .8rem 0;
    }

    .progress_box.sm .flex_box>li+li {
        margin-left: 0;
    }
    /* progress_box(E) */

    /* section_why(S) */
    [class*=section]+.section_why {
        margin-top: 6.4rem;
    }

    .main [class*=section]+.section_why {
        margin-top: 6.4rem;
    }
    
    .section_why .cont_box_wrap {
        margin: 0;
    }

    .section_why .cont_box_wrap .box[class*=col] {
        padding: 0;
    }

    .section_why .cont_box_wrap .box_in{
        padding: 3.2rem 0 2.8rem;
    }

    .section_why .cont_box_wrap .box_in .heading {
        left: 2rem;
        height: 4rem;
        padding: 0 2rem;
        font-size: 1.4rem;
        line-height: 4rem;
    }

    .section_why .cont_box_wrap .box_in .title {
        margin-bottom: 1.6rem;
        font-size: 2.4rem;
        word-break: break-word;
    }

    .section_why .cont_box_wrap .box_in .desc {
        margin-bottom: 5.4rem;
        font-size: 1.6rem;
        line-height: 1.6;
        word-break: break-word;
    }

    .section_why .cont_box_wrap .box_in .desc p {
        word-break: break-word;
    }

    .section_why .cont_box_wrap .ll .box_in .desc {
        margin-bottom: 4rem;
    }

    .section_why .cont_box_wrap .ll .manager_talk .btn_expert_video {
        text-align: center;
        padding: 0;
    }

    .section_why .cont_box_wrap .manager_talk {
        display: flex;
        flex-direction: column;
    }

    .section_why .cont_box_wrap .manager_talk .btn_expert_video {
        display: block;
        position: static;
        flex: 1;
        margin-left: .8rem;
        padding: 0;
        text-align: center;
    }

    .section_why .cont_box_wrap .manager_talk .btn_expert_video:after {
        display: none;
    }

    .section_why .cont_box_wrap .manager_talk .btn_basic {
        flex: 1;
        padding: 0;
        line-height: 3.8rem;
    }

    .section_why .cont_box_wrap .manager_talk .btn_expert_video {
        display: inline-block;
        height: 4rem;
        padding: 0;
        line-height: 3.8rem;
        font-size: 1.4rem;
        letter-spacing: -.05rem;
        color: #743fa3;
        text-align: center;
        border: 1px solid rgba(116, 63, 163, 0.5);
        background: #fff;
        box-sizing: border-box;
        transition: all .3s ease-in-out;
    }

    .section_why .cont_box_wrap .manager_talk .btn_expert_video .visible_sm {
        display: inline-block !important;
        position: relative;
        padding-left: 2.8rem;
    }

    .section_why .cont_box_wrap .manager_talk .btn_expert_video .visible_sm:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 2.4rem;
        height: 2.4rem;
        margin-top: -1.1rem;
        background: url(../images/icon_youtube_primary_48x48.png) no-repeat center center;
        background-size: 100%;
    }
    /* section_why(E) */

    /* section_bottom(S) */
    .section_bottom {
        padding: 5.2rem 0 5.2rem;
    }

    [class*=section]+.section_bottom {
        margin-top: 9.6rem;
    }

    .section_bottom .text_white {
        font-size: 2rem;
        line-height: 1.4;
    }

    .section_bottom .btn_area {
        margin-top: 3rem;
    }

    .section_bottom .btn_basic {
        /* width: 15.2rem; */
        width: auto;
    }
    /* section_bottom(E) */

    /* discript(S) */
    .section_top+.section_discript {
        margin-top: 4rem;
    }

    .section_discript .title {
        margin-bottom: 2.4rem;
        font-size: 3rem;
        line-height: 1.4;
        letter-spacing: -.1rem;
        word-break: break-word;
    }

    .section_discript .desc {
        font-size: 1.6rem;
        line-height: 1.6;
        word-break: break-word;
    }

    .section_discript .dl_box dt {
        margin-bottom: 1.6rem;
        font-size: 2rem;
        line-height: 1.4;
    }

    .section_discript .dl_box dd {
        font-size: 1.6rem;
        line-height: 1.6;
    }

    .section_discript .step_box {
        flex-flow: column nowrap;
        justify-content: flex-start;
        margin-top: 4rem;
        padding-bottom: 0;
    }

    .section_discript .step_box dl {
        display: block;
        flex: none;
        width: 100%;
        text-align: left;
    }

    .section_discript .step_box dl+dl,
    .section_discript .step_box.localization dl+dl {
        padding: 4rem 0 0 0;
    }

    .section_discript .step_box dl+dl:before {
        display: none;
    }

    .section_discript .step_box dl dt {
        display: flex;
        flex-direction: row !important;
        margin-bottom: 1.75rem;
        font-size: 2rem;
        line-height: 1.4;
        word-break: break-word;
    }

    .section_discript .step_box dl dt .num {
        width: 3.05rem;
        height: 3.05rem;
        margin: 0 1rem 1rem 0;
        box-shadow: 1.56px 1.56px 0 #1dd1d3;
    }

    .section_discript .step_box dl dt .num:before {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 1.4rem;
    }

    .section_discript .step_box dl dt > p{
        width: calc(100% - 4.05rem);
    }

    .section_discript .step_box dl:first-child dt .num:before {
        background-image: url(../images/icon_num_1.png);
    }

    .section_discript .step_box dl:nth-child(2) dt .num:before {
        background-image: url(../images/icon_num_2.png);
    }

    .section_discript .step_box dl:nth-child(3) dt .num:before {
        background-image: url(../images/icon_num_3.png);
    }

    .section_discript .step_box dl dd {
        font-size: 1.6rem;
        line-height: 1.6;
    }
    .section_discript .step_box dl dd > br{
        display: none;
    }
    /* discript(E) */

    

    /* section_player(S) */
    .section_player+[class*=section_] {
        margin-top: 6.4rem;
    }

    .section_player .player a {
        height: auto;
        padding-bottom: 83.88%;
    }

    .section_player .player .thumb:after {
        width: 7.6rem;
        height: 6.6rem;
        margin: -3.3rem 0 0 -3.8rem;
        background-image: url(../images/icon_youtube_play_150x132.png);
        background-size: 100%;
    }

    .section_player .text_box .d_table {
        padding: 4rem 2rem;
    }

    .section_player .text_box [class*=text_] {
        font-size: 1.6rem;
        line-height: 1.6;
    }

    .section_player .d_table .btn_area {
        margin-top: 2.4rem;
    }
    /* section_player(E) */

    /* ps_table(S) */
    .ps_table.flex_box {
        margin-top: 4rem;
    }

    .ps_table.flex_box .item+.item {
        margin-top: 4rem;
    }

    .ps_table.flex_box .text {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
    /* ps_table(E) */

    /* list_items(S) */
    .list_items.qa ul {
        flex-wrap: wrap;
        margin: -2.4rem 0 0;
    }

    .list_items.qa [class*=col] {
        width: 100%;
        padding: 2.4rem 0 0;
    }

    .list_items.qa [class*=col] .thumb {
        padding-bottom: 47.5%;
    }

    .list_items.qa [class*=col] .info {
        height: 11.4rem;
        padding: 1.2rem 2.4rem 1rem;
    }

    .list_items.qa [class*=col] .info .tit {
        max-height: 2.8rem;
        margin-bottom: .4rem;
        font-size: 2rem;
        line-height: 1.4;
    }

    .list_items.qa [class*=col] .info .desc {
        max-height: 4.48rem;
        font-size: 1.4rem;
        line-height: 1.6;
    }
    /* list_items(E) */

    /* section_contents(S) */
    .section_contents .testing_list {
        display: flex;
        flex-flow: column nowrap;
    }

    .section_contents .testing_list ul {
        flex: none;
    }

    .section_contents .testing_list ul+ul {
        margin-top: 2.4rem;
    }
    .section_contents .dl_list > dl > dt{
        margin-bottom: 1.6rem;
        font-size: 2rem;
    }
    .section_contents .dl_list > dl > dd{
        line-height: 150%;
        font-size: 1.6rem;
    }
    .bg_img_content{
        gap:3.6rem;
        padding-top:6.4rem;
    }
    .bg_img_content > div{
        height: auto;
        min-height: 220px;
        background-size: 200px;
    }
    .bg_img_content > div > .title{
        margin-bottom: 20px;
    }
    .bg_img_content .left_content{
        padding-right: 220px;
    }
    .bg_img_content .right_content{
        padding-left: 220px;
    }
    .process_wrap > .title{
        font-size: 2.4rem;
        margin-bottom: 2.4rem;
    }
    .process_wrap .title_box{
        gap: 4px;
        padding-left: 84px;
    }
    .process_wrap .title_box strong{
        font-size: 1.6rem;
    }
    .process_wrap .content_box{
        grid-template-columns: 80px 1fr 1fr;
        column-gap: 4px;
    }
    .process_wrap .item_box ul li{
        font-size: 1.4rem;
    }
    :lang(zh-CN) .process_wrap .item_box ul li{
        word-break: break-all;
    }
    .process_wrap .item_box .list_title{
        font-size: 1.4rem;
        line-height: 1.4;
    }
    :lang(zh-CN) .process_wrap .item_box .list_title{
        word-break: break-all;
    }
    .process_wrap .content_box .col_title{
        font-size: 1.2rem;
    }
    :lang(ja) .process_wrap .content_box .col_title{
        font-size: 1.1rem;
    }
    .process_wrap .content_box .item_box{
        padding: 36px 12px;
    }
    /* section_contents(E) */

    /* contact(S) */
    .section_content_wrap{
        padding: 0 20px;
    }
    .section_content_box{
        margin-bottom:309px;
    }
    .section_contact .contactUs > img{
        bottom: -309px;
        width: 320px;
    }
    .form_contact > .form_wrap{
        padding: 4.4rem 2.4rem;
        word-break: break-all;
    }
    .cont_contact .section_top {
        padding: 0;
    }

    .cont_contact .tab_wrap {
        display: none;
    }

    .cont_contact .section_discript .title {
        margin-bottom: 2.4rem;
    }

    .cont_contact .section_contact {
        margin-top: 4.8rem;
    }

    .cont_contact .section_contact .inner {
        margin-bottom: -9.6rem;
        padding-bottom: 30.9rem;
        /* padding-bottom: 96.5625%; */
        background: url(../images/bg_contact_sm.png) no-repeat right bottom;
        background-size: auto 30.9rem;
    }

    .cont_contact .contactUs {
        font-size: 1.6rem;
        line-height: 1.6;
    }

    .cont_contact .btn_area {
        margin-top: 4rem;
        width: 100%;
    }

    .cont_contact .btn_download {
        width: 100%;
        padding: 0 4.4rem 0 1.4rem;
        font-size: 1.8rem;
        letter-spacing: -.05rem;
        line-height: 1.2;
        word-break: break-word;
    }

    .cont_contact .btn_download small {
        margin-left: .4rem;
        font-size: 1.4rem;
    }

    .cont_contact .btn_download:after {
        right: 2rem;
        top: calc(50% - .9rem);
        width: 1.8rem;
        height: 1.8rem;
        background: url(../images/ico-download36x36.png) no-repeat center center;
        background-size: 100%;
    }
    /* contact(E) */

    /* portpolio(S) */
    .portfolio_list_wrap .list_items{
        gap: 2.4rem;
    }
    .cont_portpolio .section_portfolio {
        margin-top: 4rem;
    }
    .cont_portfolio .section_portfolio{
        margin-top: 4rem;
    }

    .cont_portpolio .section_portfolio .btn_area {
        margin-top: 4rem;
    }
    /* portpolio(E) */

    /* error(S) */
    .error_page .logo {
        width: 12.4rem;
        height: 5.6rem;
        margin-bottom: 5.6rem;
        background: url(../images/bi-purple_248x112.png) no-repeat center center;
        background-size: 100%;
    }

    .error_page .text_primary {
        margin-bottom: 20px;
        font-size: 2.4rem;
        line-height: 3.36rem;
        font-weight: 500;
        letter-spacing: -.05rem;
        width: 100%;
    }

    .error_page .cont {
        margin-bottom: 5.6rem;
        font-size: 1.4rem;
        line-height: 2.24rem;
    }

    .error_page .btn_area {
        width: 100%;
        max-width: 32rem;
    }

    .error_page .btn_basic {
        width: 100%;
        font-size: 1.6rem;
    }
    /* error(E) */

    .careers_welfare_check {
        margin-top: 6.4rem;
    }

    .careers_welfare_check .text_box {
        height: 20rem;
    }

    .careers_welfare_check .text_box .d_table {
        padding: 4rem 2rem;
    }

    .careers_welfare_check .text_box [class*=text_] {
        font-size: 1.6rem;
        line-height: 1.6;
        font-weight: 500;
    }

    .careers_welfare_check .d_table .btn_area {
        margin-top: 2.4rem;
    }

    .careers_welfare_check .text_primary {
        font-size: 2.4rem;
        word-break: keep-all;
    }

    .cont_cm svg text {
        font-size: 2rem!important;
    }
    .cont_portfolio .section_top .text_tab .tab_list {
        background-image: none;
    }

    .cont_portfolio .section_top .page_cover {
        background-image: url(../images/img_portfolio_tab_sm.png);
    }
    .cont_portfolio .section_top .page_cover .inner{
        padding-top: 56px;
    }
    .cont_portfolio.all .section_top .page_cover{
        background-image: url(../images/bg_portfolio_m_01.jpg);
    }
    .cont_portfolio.localization .section_top .page_cover{
        background-image: url(../images/bg_portfolio_m_02.jpg);
    }
    .cont_portfolio.go .section_top .page_cover{
        background-image: url(../images/bg_portfolio_m_03.jpg);
    }
    .cont_portfolio.cm .section_top .page_cover{
        background-image: url(../images/bg_portfolio_m_04.jpg);
    }
    .cont_portfolio.qa .section_top .page_cover{
        background-image: url(../images/bg_portfolio_m_05.jpg);
    }
    .cont_portfolio.gs .section_top .page_cover{
        background-image: url(../images/bg_portfolio_m_06.jpg);
    }
    .cont_portfolio.cp .section_top .page_cover{
        background-image: url(../images/bg_portfolio_m_07.jpg);
    }
    .section_top .portfolio_tab .tab_box{
        display: none;
    }
    .section_top .portfolio_tab .tab_box_mo{
        display: block;
    }
    .section_top .portfolio_tab .tab_box_mo{
        display: block;
        position: relative;
        height:100%;
    }
    .section_top .portfolio_tab .tab_box_mo .title{
        display: flex;
        align-items: center;
        padding: 0 55px 0 20px;
        width: 100%;
        height: 100%;
        font-size: 1.6rem;
        letter-spacing: -0.05rem;
        color: #fff;
        background: url(../images/ico_arrow_off.svg) no-repeat center right 20px;
    }
    .section_top .portfolio_tab .tab_box_mo .title.on{
        background-image: url(../images/ico_arrow_on.svg);
    }
    .section_top .portfolio_tab .tab_box_mo .title br{
        display: none;
    }
    .section_top .portfolio_tab .tab_box_mo .tab_list_mo{
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        background-color: #fff;
        border: 1px solid #D5D3E4;
    }
    .section_top .portfolio_tab .tab_box_mo .tab_list_mo > li{
        display: block;
        padding: 10px 20px;
        line-height: 30px;
        font-size: 1.8rem;
        color: #444;
    }
    .section_top .portfolio_tab .tab_box_mo .tab_list_mo > li.on{
        color:#743FA3;
    }
    .section_top .portfolio_tab .tab_box_mo .tab_list_mo > li > br{
        display: none;
    }

    .layer_wrap .layer_popup {
        transform: translate(0, 0);
    }
    .boxIcon {
        display: flex;
    }

    .careers .page_cover.jobs {
        display: none;
    }

    .bg_fe #footer {
        border: 0;
    }
    
}
/************************ 반응형 992px(E) ************************/



/************************ 반응형 767px(S) ************************/
@media screen and (max-width: 767px) {

    .section_top .page_cover .cont_title{
        font-size: 3.4rem;
    }
    .section_top .page_cover .desc strong{
        font-size: 1.4rem;
        line-height: 1.4;
    }
    .section_top .page_cover .desc span{
        font-size: 1.4rem;
        line-height: 1.4;
    }

    /* section_progress(S) */
    .section_progress .h_title_box {
        margin-bottom: 2.4rem;
    }
    
    .section_progress .h_title_box.mgb9 {
        margin-bottom: 4rem;
    }

    .section_progress .step_list_wrap .right {
        padding-left: 0;
    }

    .section_progress .step_list_wrap li.left[class*=step]:before {
        left: 2.15rem;
    }

    .section_progress .step_list_wrap li.right[class*=step]:before {
        left: 2.15rem;
    }

    .section_progress .step_list_wrap li[class*=step] .bottom_line {
        display: none;
    }

    .section_progress .step_list_wrap li.right.last .bottom_line {
        display: block;
        position: static;
        left: 0;
        margin: 0;
        width: 100%;
        height: 12.4rem;
        background: url(../images/dot_horizontal_bottom_sm.png) no-repeat left top;
        background-size: 32rem;
    }

    .section_progress .step_list_wrap li.step1,
    .section_progress .step_list_wrap li.step2,
    .section_progress .step_list_wrap li.step3,
    .section_progress .step_list_wrap li.step4 {
        padding-bottom: 0;
        background: none;
    }

    .section_progress .step_list_wrap li[class*=step]+li[class*=step] {
        margin-top: 1.6rem;
    }

    .section_progress .step_list_wrap li[class*=step]:before {
        top: 8rem;
        bottom: 0;
    }

    .section_progress .step_list_wrap li.right.last:before {
        bottom: 15rem;
    }

    .section_progress .step_list_wrap li .step_cont {
        padding-bottom: 2.4rem;
    }

    .section_progress .step_list_wrap li.step1 .step_cont {
        padding-bottom: 5rem;
        padding-right: 0;
    }

    .section_progress .step_list_wrap li.step1 .step_cont ul,
    .section_progress .step_list_wrap li.step2 .step_cont ul,
    .section_progress .step_list_wrap li.step3 .step_cont ul,
    .section_progress .step_list_wrap li.step4 .step_cont ul{
        margin-bottom: 2.6rem;
    }
    

    .section_progress .step_list_wrap li .step_cont:after {
        content: "";
        display: block;
        width: 100%;
        max-width: 60%;
        /* margin: 2.4rem 0 0 auto;  */
        padding-bottom: 56.483%;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center top;
    }

    .section_progress .step_list_wrap li.step1 .step_cont:after {
        padding-bottom: 61.17%;
        background-image: url(../images/bg_step1_sm.png);
    }

    .section_progress .step_list_wrap li.step2 .step_cont:after {
        background-image: url(../images/bg_step2_sm.png);
    }

    .section_progress .step_list_wrap li.step3 .step_cont:after {
        background-image: url(../images/bg_step3_sm.png);
    }

    .section_progress .step_list_wrap li.step4 .step_cont:after {
        background-image: url(../images/bg_step4_sm.png);
    }

    .section_progress .step_cont li {
        position: relative;
        padding-left: 1rem;
    }

    .section_progress .step_cont li:before {
        position: absolute;
        left: 0;
        top: 0;
    }

    .section_progress .step_list_wrap li[class*=step] .toggle_box {
        position: relative;
    }

    .section_progress .step_list_wrap li[class*=step] .toggle_box:before,
    .section_progress .step_list_wrap li[class*=step] .toggle_box:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        background: #fff;
        height: 2.4rem;
    }

    .section_progress .step_list_wrap li[class*=step] .toggle_box:before {
        top: 0;
    }

    .section_progress .step_list_wrap li[class*=step] .toggle_box:after {
        top: auto;
        height: 1.6rem;
        margin-top: .4rem;
    }

    .section_progress .step_list_wrap li.step4 .toggle_box:after {
        display: none;
    }

    .section_progress .step_list_wrap li.step2 .toggle_box,
    .section_progress .step_list_wrap li.step3 .toggle_box,
    .section_progress .step_list_wrap li.step4 .toggle_box {
        padding: 2.4rem 0 6.5rem;
    }

    .section_progress .step_list_wrap li.step4 .toggle_box {
        padding-bottom: 1.6rem;
    }

    .section_progress .toggle_box .box_in {
        padding: 2rem;
        background: #743fa3;
        box-shadow: .4rem .4rem #1dd1d3;
    }

    .section_progress .toggle_box .box_tit {
        display: block;
        margin-bottom: 1.6rem;
    }

    .section_progress .toggle_box .box_tit:before {
        display: none;
    }
    .section_progress .toggle_box.on .box_tit strong {
        background-color: transparent;
    }

    .section_progress .toggle_box .box_tit strong {
        display: block;
        height: auto;
        padding: 0;
        line-height: 1.6;
        color: #fff;
        border: 0;
        background-color: transparent !important;
    }

    .section_progress .toggle_box .cont {
        position: static;
        width: 100%;
        max-width: 100%;
        padding: 0;
        opacity: 1;
        visibility: visible;
        background: none;
        box-shadow: none;
    }

    .section_progress .toggle_box .cont ul {
        padding-top: 1.6rem;
    }

    .section_progress .toggle_box .cont ul li {
        line-height: 1.6;
    }
    :lang(zh-CN) .section_progress .toggle_box .cont ul li {
        word-break: break-all;
    }

    .section_progress .toggle_box .cont ul li+li {
        margin-top: 1.6rem;
    }

    .section_progress .toggle_box .btn_area {
        margin-top: 2.4rem;
        text-align: right;
    }

    .section_progress .toggle_box .btn_area .btn_basic {
        min-width: 13rem;
    }

    .section_progress .step_list_wrap .h_title {
        position: relative;
        padding-left: 6.4rem;
    }

    .section_progress .step_list_wrap .h_title .num {
        width: 4.56rem;
        height: 4.56rem;
        line-height: 4.56rem;
        box-shadow: .234rem .234rem #1dd1d3;
    }

    .section_progress .step_list_wrap .h_title .num:after {
        background-size: auto 2.2rem;
    }

    .section_progress .step_list_wrap .step1 .h_title .num:after {
        background-image: url(../images/icon_num_1_27x44.png);
    }

    .section_progress .step_list_wrap .step2 .h_title .num:after {
        background-image: url(../images/icon_num_2_27x44.png);
    }

    .section_progress .step_list_wrap .step3 .h_title .num:after {
        background-image: url(../images/icon_num_3_27x44.png);
    }

    .section_progress .step_list_wrap .step4 .h_title .num:after {
        background-image: url(../images/icon_num_4_27x44.png);
    }

    .section_progress .step_list_wrap .h_title .en {
        font-size: 2rem;
        line-height: 1.4;
        word-break: break-word;
    }

    .section_progress .step_list_wrap .step_cont {
        padding-left: 6.4rem;
    }

    .section_progress .step_cont .text {
        margin-bottom: 2.4rem;
        font-size: 1.6rem;
        line-height: 1.6;
        margin-right: auto;
    }

    .section_progress .step_cont li {
        font-size: 1.4rem;
        line-height: 1.6;
    }

    .section_progress .step_cont li+li {
        margin-top: .8rem;
    }
    .cont_cm .section_progress .step_list_wrap li.step1,
    .cont_cm .section_progress .step_list_wrap li.step2,
    .cont_cm .section_progress .step_list_wrap li.step3,
    .cont_cm .section_progress .step_list_wrap li.step4,
    .cont_cm .section_progress .step_list_wrap li.step5,
    .cont_cm .section_progress .step_list_wrap li.step6 {
        padding-bottom: 0;
        background: none;
    }

    .cont_cm .section_progress .step_list_wrap li.step1 .step_cont {
        padding-bottom: 5rem;
        padding-right: 0;
    }

    .cont_cm .section_progress .step_list_wrap li.step1 .step_cont:after {
        padding-bottom: 45%;
        background-image: url(../images/bg_cm_step1.png);
    }

    .cont_cm .section_progress .step_list_wrap li.step2 .step_cont:after {
        background-image: url(../images/bg_cm_step2.png);
    }

    .cont_cm .section_progress .step_list_wrap li.step3 .step_cont:after {
        background-image: url(../images/bg_cm_step3.png);
    }
    .cont_cm .section_progress .step_list_wrap li.step3 .step_cont,
    :lang(en) .cont_cm .section_progress .step_list_wrap li.step3 .step_cont{
        padding-right: 0;
    }

    .cont_cm .section_progress .step_list_wrap li.step4 .step_cont:after {
        padding-bottom: 62%;
        background-image: url(../images/bg_cm_step4.png);
    }

    .cont_cm .section_progress .step_list_wrap li.step5 .step_cont:after {
        padding-bottom: 73%;
        background-image: url(../images/bg_cm_step5.png);
    }
    .cont_cm .section_progress .step_list_wrap li.step5 .step_cont{
        padding-right: 0;
    }

    .cont_cm .section_progress .step_list_wrap li.step6 .step_cont{
        padding-bottom: 3rem;
    }
    .cont_cm .section_progress .step_list_wrap li.step6 .step_cont:after {
        background-image: url(../images/bg_cm_step6.png);
    }

    .cont_cm .section_progress .step_list_wrap li.step6 .toggle_box:after {
        display: none;
    }

    .cont_cm .section_progress .step_list_wrap li.step2 .toggle_box,
    .cont_cm .section_progress .step_list_wrap li.step3 .toggle_box,
    .cont_cm .section_progress .step_list_wrap li.step4 .toggle_box,
    .cont_cm .section_progress .step_list_wrap li.step5 .toggle_box,
    .cont_cm .section_progress .step_list_wrap li.step6 .toggle_box {
        padding: 2.4rem 0 6.5rem;
    }

    .section_progress .step_list_wrap li.right.last:before {
        bottom: 15rem;
        height: calc(100% - 20.5rem);
    }
    :lang(zh-CN) .section_progress .step_list_wrap li.right.last:before {
        height: calc(100% - 32rem);
    }
    .cont_cm .section_progress .step6 .step_cont {
        height: 45rem;
    }

    .cont_cm .section_progress .step_cont .text {
        margin-bottom: 2.4rem;
        font-size: 1.6rem;
        line-height: 1.6;
        margin-right: auto;
        word-break: break-word;
    }

    .cont_cm .section_progress .step_list_wrap li.right.last .bottom_line {
        display: block;
        position: static;
        left: 0;
        margin: 3px 0 0;
        width: 100%;
        height: 12.4rem;
        background: url(../images/dot_horizontal_bottom_sm.png) no-repeat left top;
        background-size: 32rem;
    }

    .cont_cm .section_progress .step_list_wrap li.right.last:before {
        bottom: 19rem;
    }
    :lang(zh-CN) .section_progress .step_list_wrap li.step2,
    :lang(ja) .section_progress .step_list_wrap li.step2 {
        background-image: none;
    }

    :lang(zh-CN) .section_progress .step_list_wrap li.step2 .step_cont:after,
    :lang(ja) .section_progress .step_list_wrap li.step2 .step_cont:after {

        background-size: cover;
    }

    :lang(zh-CN) .section_progress .step_list_wrap li.step2 .step_cont:after {
        background-image: url(../images/bg_step2_zh-CN.png);
    }

    :lang(ja) .section_progress .step_list_wrap li.step2 .step_cont:after {
        background-image: url(../images/bg_step2_ja.png);
    }
    /* section_progress(E) */
    .section_progress .step_list_wrap li .step_cont:after {
        max-width: 80%;
        padding-bottom: 76.483%;
    }

    .section_progress .step_list_wrap li.step1 .step_cont:after {
        padding-bottom: 81.17%;
    }

    /* 다국어 영역 (S) */
    :lang(ja) .cont_pv .section .dl_box dd,
    :lang(zh-CN) .cont_pv .section .dl_box dd {
        word-break: break-all;
    }

    :lang(ja) .cont_pv .section .title-box p,
    :lang(zh-CN) .cont_pv .section .title-box p {
        word-break: break-all;
    }

    :lang(ja) .cont_pv .section .dl_box dd strong,
    :lang(zh-CN) .cont_pv .section .dl_box dd strong {
        word-break: break-all;
    }

    :lang(ja) .cont_pv .section .dl_box dt,
    :lang(zh-CN) .cont_pv .section .dl_box dt {
        word-break: break-all;
    }

    :lang(ja) .cont_pv .section .dl_box dd span,
    :lang(zh-CN) .cont_pv .section .dl_box dd span {
        word-break: break-all;
    }

    :lang(en) .cont_pv .section .dl_box dd,
    :lang(ko) .cont_pv .section .dl_box dd {
        word-break: keep-all;
    }

    :lang(en) .cont_pv .section .title-box p,
    :lang(ko) .cont_pv .section .title-box p {
        word-break: keep-all;
    }

    :lang(en) .cont_pv .section .dl_box dd strong,
    :lang(ko) .cont_pv .section .dl_box dd strong {
        word-break: break-all;
    }

    :lang(en) .cont_pv .section .dl_box dt,
    :lang(ko) .cont_pv .section .dl_box dt {
        word-break: keep-all;
    }

    :lang(en) .cont_pv .section .dl_box dd span,
    :lang(ko) .cont_pv .section .dl_box dd span {
        word-break: keep-all;
    }
    /* 다국어 영역 (E) */

    /* Services (S) */
    .services_box{
        grid-template-columns: repeat(2, 1fr);
    }
    .services_box .box{
        height: 296px;
    }
    .services_box .box .img_box{
        margin: 0 auto;
        width: 130px;
        height: 130px;
        background-size: 130px !important;
    }
    .services_box .box .team_name{
        font-size: 1.2rem;
        line-height: 1.3;
    }
    .services_box .box .title{
        font-size: 1.6em;
    }
    :lang(zh-CN) .services_box .box .title{
        word-break: break-all;
    }
    .services_box .box > img{
        max-width: 100%;
    }
    
    /* Services (E) */

    /* 기타 공통 클래스 영역 (S) */
    .cont_pv .section {
        margin-top: 3.9rem;
        margin-bottom: 8.8rem;
    }

    .cont_pv .section .title-box h2 {
        font-size: 2.6rem;
        color: #743FA3;
        margin-bottom: 2rem;
    }

    .cont_pv .section .title-box h2>span {
        font-size: 1.5rem;
        display: block;
        line-height: 200%;
        font-weight: 500;
    }

    .cont_pv .section .title-box p {
        font-size: 1.77rem;
        line-height: 1.63;
        word-break: keep-all;
    }

    .cont_pv .section .dl_box {
        margin-top: 4rem;
        border-top: 1px solid #E1E1E1;
    }

    .cont_pv .section .dl_box dl {
        padding: 3.2rem 1.2rem;
        border-top: 1px solid #E1E1E1;
    }

    .cont_pv .section .dl_box dt {
        margin-bottom: 2.4rem;
        font-weight: 700;
        font-size: 1.77rem;
        line-height: 1.285;
        color: #743FA3;
        word-break: keep-all;
    }

    .cont_pv .section .dl_box dd {
        font-size: 1.55rem;
        line-height: 1.66;
        word-break: keep-all;
    }

    .cont_pv .section .dl_box dd strong {
        font-size: 2rem;
        font-size: 1.55rem;
        font-weight: 700;
        word-break: keep-all;
    }

    .cont_pv .section .dl_box dd span {
        display: block;
        font-size: 1.55rem;
        color: #828282;
        font-weight: 400;
        word-break: keep-all;
    }

    .cont_pv .section .dl_box dd span.flex {
        font-size: 1.55rem;
        color: #828282;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .cont_pv .section .dl_box dd div.point {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background-color: #828282;
    }

    .cont_pv .section .dl_box dd strong.point {
        font-size: 2rem;
        font-weight: 500;
        line-height: 3.4rem;
    }

    .cont_nt .section {
        margin-top: 3.9rem;
        margin-bottom: 6.6rem;
    }

    .cont_nt .section .title-box h2 {
        font-size: 2.4rem;
        color: #743FA3;
        margin-bottom: 2.3rem;
    }

    .cont_nt .section .title-box p {
        font-size: 1.6rem;
        line-height: 1.63;
        word-break: keep-all;
    }

    .cont_nt .section .p2 {
        font-size: 1.8rem;
    }

    .cont_nt .section .dl_box dl {
        margin-bottom: 2.4rem;
    }

    .cont_nt .section .dl_box dt {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.66;
        color: #111;
    }

    .cont_nt .section .dl_box dd {
        margin-left: 15px;
        font-size: 1.4rem;
        line-height: 1.66;
        color: #111;
    }

    .cont_nt .section .dl_box dd dl {
        margin-bottom: 0;
    }

    .cont_nt .section .dl_box dd dl dt {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.66;
        color: #111;
    }

    .cont_nt .section .dl_box dd dl dd {
        margin-left: 18px;
        font-size: 1.4rem;
        line-height: 1.66;
        color: #828282;
    }

    .cont_nt .section .dl_box dd span {
        display: block;
        font-size: 1.4rem;
        color: #828282;
        font-weight: 400;
    }

    .cont_nt .footer-text {
        text-align: right;
        font-size: 1.2rem;
        line-height: 1.63;
        color: #111;
    }

    .cont_cm .section_progress .step_list_wrap li.step1 .step_cont:after {
        padding-bottom: 74%;
    }

    .notice_wrap {
        width: 100%;
        /* height: 70px; */
        background-color: #743FA3;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .notice_wrap .notice_text a {
        font-size: 1.8rem;
        font-weight: 700;
        color: #FFF;
    }

    .arrow-icon {
        width: 17.5px;
        height: 14px;
        margin-top: 2px;
        margin-left: 7px;
    }

    .PrivacyChangeButton {
        /* min-width: 36rem; */
        height: 4.5rem;
        width: 100%;
        font-size: 1.3rem;
        position: relative;
        bottom: 0;
        right: 0;
    }

    .PrivacyChangeButton>svg {
        margin-left: 3rem;
    }

    .careers.section_blog {
        padding-top: 6.4rem;
    }
    /* 기타 공통 클래스 영역 (E) */
}
/************************ 반응형 767px(E) ************************/


/************************ 반응형 499px(S) ************************/
@media screen and (max-width: 499px) {
    :lang(en) .services_box .box .title,
    :lang(ja) .services_box .box .title{
        margin-bottom:15px;
    }
    :lang(en) .services_box .box .img_box,
    :lang(ja) .services_box .box .img_box{
        width: 120px;
        height: 120px;
        background-size: 120px !important;
    }
    .section_progress .step_list_wrap li .step_cont:after {
        max-width: 100%;
        padding-bottom: 96.483%;
    }
    .section_progress .step_list_wrap li.step1 .step_cont:after {
        padding-bottom: 101.17%;
    }

    .cont_cm .section_progress .step_list_wrap li .step_cont:after {
        max-width: 100%;
        padding-bottom: 96.483%;
    }

    .cont_cm .section_progress .step6 .step_cont{
        height:auto;
    }

    .cont_cm .section_progress .step_list_wrap li.step6 .step_cont:after {
        background-size : 100% auto;
        padding-bottom : 70%;
    }

    .section_discript .title {
        margin-bottom: 2.4rem;
        font-size: 2.4rem;
    }

    .bg_img_content{
        gap: 1rem;
    }

    .bg_img_content .left_content{
        padding-right: 0;
        background-position: center bottom;
    }
    .bg_img_content .right_content{
        padding-left: 0;
        background-position: center bottom;
    }
    .bg_img_content > div{
        margin-bottom: 30px;
        padding-bottom: 290px;
        background-size: 320px auto;
    }
    .bg_img_content > div:last-child{
        margin-bottom: 0;
    }
    .bg_img_content > div > .title{
        font-size: 2.4rem;
        line-height: 33px;
    }
    .bg_img_content > div > p{
        font-size: 1.6rem;
    }
    :lang(zh-CN) .bg_img_content > div > p{
        word-break: break-all;
    }
}
@media screen and (max-width: 320px) {
    .section_top_services .tab_box_mo .tab_list_mo{
        top: 52px;
    }
}
/************************ 반응형 499px(E) ************************/